import { ArwesThemeProvider, Button, FrameUnderline } from "@arwes/core";
import React from "react";
import Particles from "react-tsparticles";
import {
  BulletCont,
  BulletImg,
  BulletText,
  ColumnDiv,
  EarnCont,
  OffHeader,
  OffSummary,
  OffSummaryCont,
  OffSummaryEarn,
  RowDiv,
} from "./OfferingElements";
import { loadFull } from "tsparticles";
import Crystal from "../../images/crystal 5.png";
const Earn = () => {
  const palette = {
    primary: { main: "#70edfe" },
    secondary: { main: "#c466dc" },
    neutral: { main: "#001711" },
    text: {
      root: "#35efaa",
      headings: "#40ffce",
      link: "#c466dc",
      linkHover: "#d491fa",
    },
  };

  let themeSettings = { palette };

  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <ArwesThemeProvider themeSettings={themeSettings}>
      <EarnCont>
        <Particles //style={{marginTop: '1000px'}}
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          width="100%"
          options={{
            fullScreen: { enable: false },
            fpsLimit: 50,

            particles: {
              links: {
                color: "#FCC625",
                distance: 150,
                enable: false,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "bottom",
                enable: true,

                random: false,
                speed: 4,
                straight: true,
              },
              number: {
                density: {
                  enable: true,
                  area: 1000,
                },
                value: 25,
              },
              opacity: {
                value: 1,
                anim: {
                  enable: true,
                },
              },
              shape: {
                type: "image",
                image: {
                  src: "/z-coin.png",
                  width: 100,
                  height: 100,
                },
              },
              size: {
                value: 30,
                random: true,
                anim: {
                  enable: true,
                  speed: 3,
                },
              },
            },

            detectRetina: true,
          }}
        />

        <OffSummaryCont style={{ zIndex: "4", marginTop: "-600px" }}>
          <OffHeader style={{ alignSelf: "center" }}>Earn!</OffHeader>
          <OffSummaryEarn>
            With blockchain at its core, Fusion FighterZ introduces a dynamic
            player-centric economy. Dive into diverse earning avenues, reaping
            tangible rewards while indulging in fierce multiplayer battles. From
            free entry points to lucrative rewards, Fusion FighterZ promises a
            balanced battlefield. Ready to tap into the treasure trove? Dive in
            now.
          </OffSummaryEarn>

          <RowDiv>
            {/* 
                        <ColumnDiv>
                        
                        
                            <BulletCont>
                                <BulletImg src={Crystal}/>
                                <BulletText>Competing in the Metaverse Grand Tournament</BulletText>
                            </BulletCont>

                            <BulletCont>
                                <BulletImg src={Crystal}/>
                                <BulletText>Leaderboard Prize distributions</BulletText>
                            </BulletCont>

                            <BulletCont>
                                <BulletImg src={Crystal}/>
                                <BulletText>Battle Pass</BulletText>
                            </BulletCont>
                        
                        </ColumnDiv>

                        <ColumnDiv>
                        
                        
                            <BulletCont>
                                <BulletImg src={Crystal}/>
                                <BulletText>Fused moves and fighters (Battle Moves and Fusion FighterZ)</BulletText>
                            </BulletCont>

                            <BulletCont>
                                <BulletImg src={Crystal}/>
                                <BulletText>Fusion Crystals</BulletText>
                            </BulletCont>

                            <BulletCont>
                                <BulletImg src={Crystal}/>
                                <BulletText>Fusion Lab rentals</BulletText>
                            </BulletCont>
                        
                        </ColumnDiv> */}
          </RowDiv>
          <Button
            style={{ alignSelf: "flex-end", marginTop: "10px" }}
            palette="primary"
            FrameComponent={FrameUnderline}
            onClick={() =>
              window.open(
                "https://whitepaper.fusionfighterz.io/#play-and-earn-economy",
                "_blank"
              )
            }
          >
            Learn More
          </Button>
        </OffSummaryCont>
      </EarnCont>
    </ArwesThemeProvider>
  );
};

export default Earn;
