import React from "react";
import {
  SocialIcon,
  HeaderContainer,
  Column,
  ContentSummary,
  ContentHeaderGS,
  ContainerQuick,
  ContentCont,
  GIFImage,
  AboutImage,
} from "./ContentElements";

import "react-responsive-carousel/lib/styles/carousel.min.css"; //requires loader
import { useMediaQuery } from "react-responsive";
import { FaDiscord } from "react-icons/fa";
import { ArwesThemeProvider } from "@arwes/core";
import FadeInSection from "../FadeIn";
import BoardApe from "../../images/board.png";

const Quick = () => {
  /*
        React Responsiveness Media Queries
    */
  // const isTabDevice = useMediaQuery({
  //     query: "(max-width: 830px)",
  // });

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 620px)",
  });

  const palette = {
    primary: { main: "#70edfe" },
    secondary: { main: "#c466dc" },
    neutral: { main: "#001711" },
    text: {
      root: "#35efaa",
      headings: "#40ffce",
      link: "#c466dc",
      linkHover: "#d491fa",
    },
  };

  let themeSettings = { palette };

  return (
    <ArwesThemeProvider themeSettings={themeSettings}>
      <ContainerQuick id={"about"}>
        <HeaderContainer>
          {/* <TopLine>Fusion FighterZ</TopLine> */}
          <FadeInSection>
            <ContentHeaderGS>THE METAVERSE GRAND TOURNAMENT</ContentHeaderGS>
          </FadeInSection>
        </HeaderContainer>

        <Column>
          {/* <Project header='Play to Earn'>

                            <ContentSummary>Fusion FighterZ is a NFT Battle Arena located in the Metaverse. With the rise of Play to Earn gaming people ought to own and be compensated for the value and effort they put into their games.</ContentSummary>
                            <ContentSummary> Enter the Fusion FighterZ Battle Arena to collect, fight, and earn!</ContentSummary>

                        </Project> */}

          {isMobileDevice ? (
            <FadeInSection>
              <ContentCont>
                <GIFImage src={BoardApe} />
                <ContentSummary>
                  Dive into an unparalleled PVP contest beckoning every web3
                  warrior. Will you rise to the occasion? Summon your distinct
                  Fusion Fighter and step into the arena. Alternatively, amplify
                  your battle roster by registering your top-tier NFT projects
                  with our groundbreaking Fight License.
                </ContentSummary>

                {/* <SocialIcon>
                                    <FaDiscord size={'60px'}/>
                                </SocialIcon> */}
              </ContentCont>
            </FadeInSection>
          ) : (
            <FadeInSection>
              <AboutImage src={BoardApe} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "100px",
                  marginRight: "50px",
                }}
              >
                <ContentSummary>
                  Dive into an unparalleled PVP contest beckoning every web3
                  warrior. Will you rise to the occasion? Summon your distinct
                  Fusion Fighter and step into the arena. Alternatively, amplify
                  your battle roster by registering your top-tier NFT projects
                  with our groundbreaking Fight License.
                </ContentSummary>
                <div style={{ marginTop: "50px" }} />
                {/* <SocialIcon>
                                        <FaDiscord size={'60px'}/>
                                    </SocialIcon> */}
              </div>
            </FadeInSection>
          )}
        </Column>
      </ContainerQuick>
    </ArwesThemeProvider>
  );
};

export default Quick;

//<ContentSummary>Fusion FighterZ is a NFT multiplayer battle arena within the Metaverse inspired by Axie Infinity and the Pokemon battle system, where players can earn tokens through skilled gameplay and participating in the ecosystem. Players can battle, improve, and fuse their NFT fighters for an immersive and lucrative gameplay experience. Using Blockchain economic design allows Fusion FighterZ to develop a Play-To-Earn gaming ecosystem. Anyone from anywhere can earn real financial rewards while having fun playing and mastering a strategy based multiplayer game.</ContentSummary>
