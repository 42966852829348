import './App.css';
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import Crystal from './images/crystal 5.png'
import {Container, WholeCrystal} from './components/Fusion/FusionElements'
import ReactGA from 'react-ga';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  const [loading, setLoading] =useState(true)
  //const TRACKING_ID = 'G-QFGDYGFMZN';
  

  const setUpGA = () => {
    ReactGA.initialize('UA-221712617-1');
    ReactGA.pageview('Init page view')
  }

  const handleLoading = () => {
    setLoading(false)
  }

  useEffect(() => {

      window.addEventListener("load",handleLoading);
      setUpGA();
      return () => {
        window.removeEventListener("load",handleLoading);
        
      }
  }, [])

  return (
    <div style={{width: '100%'}}>
      {loading === false ? (
          <ParallaxProvider>

          
            <Router>
             
                <Switch>
                  <Route exact path='/'>
                    
                    <Home/>
                  </Route>
                </Switch>
              
            </Router>
          </ParallaxProvider>
       
      )
        :
        ( <Container>
            <WholeCrystal src={Crystal}/>
          </Container>
        )
      }
    
    </div>
    
  );
}

export default App;
