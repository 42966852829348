import styled from 'styled-components';


export const OfferingContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 100%;
    justify-content: space-around;


    @media screen and (max-width: 950px) {
        flex-direction: column;
    }

    @media screen and (max-width: 670px) {
       margin-top: 100px;
        flex-direction: column;
    }

`

export const OffImageCont = styled.div`
    display: flex;
    align-items:center;
    margin-right: 40px;
    margin-left: 40px;
    margin-top:40px;

    @media screen and (max-width: 670px) {
        align-self: center;
    }
`

export const OffSummaryCont = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-right: 60px;

    @media screen and (max-width: 950px) {
        margin-top: 0px;
       margin-right: 0px;
        align-items: center;
    }

    @media screen and (max-width: 670px) {
       margin-top: 0px;
       margin-right: 0px;
       padding: 25px;
    }


`

export const ButtonCont = styled.div`
    align-self: flex-end;
    margin-top: 10px;

    @media screen and (max-width: 950px) {
       align-self: center;
    }
`
export const OffImage = styled.img`
    width: 34rem;

    @media screen and (max-width: 670px) {
       width: 16rem;
       
    }
`
export const OffImageOrb = styled.img`
    width: 500px;
`


export const OffHeader = styled.p`
    max-width: 800px;
    margin-bottom: 50px;
    font-size: 54px;
    text-align: center;

    color:  #fff ;
    font-family: 'Content';
    @media screen and (max-width: 1150px) {
        font-size: 38px;
        margin-bottom: 25px;
    }
    @media screen and (max-width: 670px) {
        font-size: 30px;
        margin-bottom: 15px;
    }
`

export const OffSummary = styled.p`
    max-width: 800px;
    margin-bottom: 5px;
    font-size: 20px;
    text-align: left;
    width: 550px;
    line-height: 25px;
    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */
    @media screen and (max-width: 1600px) {
       font-size: 14px;
       width: 500px;
    }
    @media screen and (max-width: 1420px) {
       width: 400px;
    }
    @media screen and (max-width: 1150px) {
        width: 350px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        width: 80%;
        line-height: 20px;
        align-self: center;
    }
`


export const OffSummaryEarn = styled.p`
    max-width: 800px;
    margin-bottom: 5px;
    font-size: 26px;
    text-align: left;
    align-self: center;
    line-height: 34px;
    width: 100%;
    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */
    @media screen and (max-width: 1600px) {
       font-size: 14px;
       width: 500px;
    }
    @media screen and (max-width: 1420px) {
       width: 400px;
    }
    @media screen and (max-width: 1150px) {
        width: 350px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        width: 80%;
        line-height: 20px;
        align-self: center;
    }
`

export const TeamCont = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
    margin-bottom: 50px;

`

export const TeamMemCont = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
`

export const IndTeamCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;

`

export const TeamSumCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    margin-top: 30px;

    @media screen and (max-width: 1600px) {
       width: 500px;
    }

    @media screen and (max-width: 740px) {
       width: 400px;
    }
`


export const TeamImage = styled.img`
    width: 250px;
    background-color: #3d3d3d;
    border-radius: 50%;
    @media screen and (max-width: 1000px) {
       width: 200px;
    }
    @media screen and (max-width: 670px) {
       width: 100px;
    }
`
export const TeamCaption = styled.h2`
    color: #fff;
    font-family: 'Custom';
    align-self: center;
    font-size: 22px;
    @media screen and (max-width: 1030px) {
       font-size: 22px;
       margin-left: 25%;
    }
    @media screen and (max-width: 780px) {
       font-size: 18px;
       margin-left: 25%;
    }
    @media screen and (max-width: 670px) {
       font-size: 12px;
       margin-left: 0px;
    }
`
export const PharoImg = styled.img`
    height: 225px;
    @media screen and (max-width: 670px) {
        height: 155px;
    }
`

export const LiscenceContainer = styled.div`
     display: flex;
    flex-direction: row;
    height: 100%;
    align-items: 'center';
    justify-content: center;

    
    @media screen and (max-width: 1000px) {
       flex-direction: column;
       align-items: center;
       margin-bottom: 50px;
    }

`

export const LiscenceImageCont = styled.div`
    display: flex;
    align-items:flex-start;
    margin-right: 40px;
    margin-left: 0px;
    
    @media screen and (max-width: 670px) {
       align-self: center;
       margin-right: 0px;
    }

`

export const ContainerLiscence  = styled.div`
      
    background-color: #000;//#090E13;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-top: 50px;

    @media screen and (max-width: 1000px) {
       flex-direction: column;
       
    }
    @media screen and (max-width: 670px) {
       flex-direction: column;
       background-color: #000;
    }

`


export const BulletCont = styled.div`
    display: flex;
    align-items: center;
`

export const BulletImg = styled.img`
    width: 80px;
`

export const BulletText = styled.div`

max-width: 800px;
    margin-bottom: 5px;
    font-size: 26px;
    text-align: left;
    width: 550px;
    line-height: 25px;
    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */
    @media screen and (max-width: 1600px) {
       font-size: 14px;
       width: 500px;
    }
    @media screen and (max-width: 1420px) {
       width: 400px;
    }
    @media screen and (max-width: 1150px) {
        width: 350px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        width: 80%;
        line-height: 20px;
        align-self: center;
    }
    
`

export const ColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
`

export const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
`

export const EarnCont = styled.div`
    height: 700px;
    width: 100vw;
    margin-bottom: 200px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;


    @media screen and (max-width: 670px) {
        height: 600px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
`