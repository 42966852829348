import React from 'react'
import { ContentComponent, HeaderContainer, Heading, Content, Column,  ContentHeader,  Container3, DoubleContainer } from './ContentElements'

import MockBattle_LG from '../../images/MockBattleUI-300.png'
import MockMoves_LG from '../../images/mockMoves-300.png'
import Token_LG from '../../images/tokens-300.jpg'
import Fusion_LG from '../../images/eth-300.jpg'

// import {  Text, } from 'arwes';
import {ArwesThemeProvider, Button, Figure, FrameUnderline}  from '@arwes/core'

const FeatureSet = () => {


    const palette = {
        primary: { main: '#40ffce' },
        secondary: { main: '#c466dc' },
        neutral: { main: '#000' },
        text: {
          root: '#35efaa',
          headings: '#40ffce',
          link: '#c466dc',
          linkHover: '#d491fa'
        }
      };

    let themeSettings = {palette};

    return (
        <ArwesThemeProvider themeSettings={themeSettings}>
            <Container3 id={'gameElements'}>
                <HeaderContainer>
                    {/* <TopLine>Fusion FighterZ</TopLine> */}
                    <Heading>Game Elements</Heading>
                </HeaderContainer>

                <ContentComponent>
                    <DoubleContainer>
                        <Column>
                            {/* <Frame 
                                level={0}
                                corners={6}
                                style={{width: '300px', }}
                            >
                            <ContentHeader>Battle </ContentHeader>
                                <FeatureImage src={MockBattle}/>
                                <Content>The Battle Arena is where Fusion FighterZ go to join PVP lobbies of 8 players in 1 vs 1 combat until only one winner is left!</Content>
                                <Button FrameComponent={FrameUnderline}>Learn More</Button>
                            </Frame> */}
                             <Figure
                            
                                src={MockBattle_LG}
                                alt='Battle'
                                style={{height: "100%", backgroundColor: '#202020'}}
                            >
                                <ContentHeader>Battle </ContentHeader>
                                
                                <Content>
                                Battling consists of turn based combat, rewarding players on creative and effective strategies.
                                
                                </Content>
                                <Button onClick={() => window.open('https://whitepaper.fusionfighterz.io/gameplay/battle-mechanics', '_blank')} palette='secondary' FrameComponent={FrameUnderline}>Learn More</Button>
                            
                            </Figure>


                            {/* <Card
                                level={1}
                                image={{
                                src: MockBattle,
                                alt: 'Battle',
                                style: {}
                                }}
                                title='Battle'
                                options={
                                    <Button palette='primary'>
                                      <Text>Learn More</Text>
                                    </Button>
                                  }
                                style={{ width: 400,  }}
                            >
                                <Text>
                                    The Battle Arena is where Fusion FighterZ go to join PVP lobbies of 8 players in 1 vs 1 combat until only one winner is left!
                                </Text>
                            </Card> */}
                            
                            {/* <ContentFeatureCard>
                                <ContentHeader>Battle </ContentHeader>
                                <FeatureImage src={MockBattle}/>
                                <Content>The Battle Arena is where Fusion FighterZ go to join PVP lobbies of 8 players in 1 vs 1 combat until only one winner is left!</Content>
                            </ContentFeatureCard> */}
                        </Column>
                        <Column>

                            <Figure
                                
                                src={MockMoves_LG}
                                alt='Moves'
                                style={{height: "100%", backgroundColor: '#202020'}}
                            >
                                <ContentHeader>Moves </ContentHeader>
                                
                                <Content>
                                Fusion FighterZ utilize Battle Moves to develop unique fighting strategies. Battle Moves are their own NFTs and are valuable resources within the economy.
                                </Content>
                                <Button onClick={() => window.open('https://whitepaper.fusionfighterz.io/gameplay/battle-moves', '_blank')} palette='secondary' FrameComponent={FrameUnderline}>Learn More</Button>
                            
                            </Figure>
                            {/* <ContentFeatureCard>
                                <ContentHeader>Moves </ContentHeader>
                                <FeatureImage src={MockMoves}/>
                                <Content>Fusion FighterZ learn and utilize Battle Moves to develop different fighting strategies. Battle Moves are their own NFTs and are valuable resources within the Fusion FighterZ economy.</Content>
                            </ContentFeatureCard> */}
                        </Column>
                    </DoubleContainer>

                    <DoubleContainer>

                    <Column>

                        <Figure
                                
                                src={Token_LG}
                                alt='Battle'
                                style={{height: "100%", backgroundColor: '#202020', }}
                            >
                                <ContentHeader>Tokens </ContentHeader>
                                <Content>Interacting with the games ecosystem will reward players with tokens such as Fusion Crystals and the native game token. These tokens help drive the Play and Earn mechanics.</Content>

                                <Button onClick={() => window.open('https://whitepaper.fusionfighterz.io/economy/game-token-usdzcoin', '_blank')} palette='secondary' FrameComponent={FrameUnderline}>Learn More</Button>
                            
                        </Figure>
                        {/* <ContentFeatureCard>
                            <ContentHeader>Tokens </ContentHeader>
                            <FeatureImage src={Token}/>
                            <Content>Tokens drive the in game economy as well as the Play to Earn mechanics. FFZ tokens are the native game token used to trade and are earned from battle. FCZ tokens are Fusion Crystals also earned in game and used in Fusion.</Content>
                        </ContentFeatureCard> */}
                    </Column>
                        <Column>

                            <Figure
                                
                                src={Fusion_LG}
                                alt='Battle'
                                style={{height: "100%", backgroundColor: '#202020'}}
                            >
                                <ContentHeader>Fusion </ContentHeader>
                                    <Content>Fusion is the process in which two FighterZ are used to summon a third completely new fighter. The fused fighter is a brand new NFT that inherits cosmetic and game traits.</Content>
                            
                           
                                
                                <Button onClick={() => window.open('https://whitepaper.fusionfighterz.io/fusion/fusion-intro', '_blank')} palette='secondary' FrameComponent={FrameUnderline}>Learn More</Button>
                            
                            </Figure>

                            {/* <ContentFeatureCard>
                                <ContentHeader>Fusion </ContentHeader>
                                <FeatureImage src={Fusion}/>
                                <Content>Fusion is the process where two fighters come together to summon a third completely new fighter. The fused fighter is a brand new NFT that inherits cosmetic and game based traits.</Content>
                            </ContentFeatureCard> */}
                        </Column>
                        
                    </DoubleContainer>
                    
                </ContentComponent>
                
            </Container3>

        </ArwesThemeProvider>
        
    )
}

export default FeatureSet
