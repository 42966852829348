import styled from 'styled-components';
// import Bg1 from '../../images/purp-bg2.jpg'
// import Bg2 from '../../images/light-bg-blue.jpg'
// import Bg0 from '../../images/purp-bg.jpg'
import BgMobile from '../../images/mobileBG.png'
import BgFighterz from '../../images/fighterz.png'

import BgNew from '../../images/Modern_background_7.jpg'
import BattleBg from '../../images/HeroBattle.png'
// import ArenaBg from '../../images/arenaBg.jpg'
// import BgAura from '../../images/bgAura.png'
import BgAura2 from '../../images/bgAura2.png'


export const HeroContainer = styled.div`
    
    display: flex;

    z-index: 1;
    padding-top: 100px;
    height: 976px;
    width: 100vw;
    
    background-position: 0;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(${BgFighterz}); */
    background-image: url(${BgAura2});
    background-position: center;
    background-color: #090E13;
    @media screen and (max-width: 670px) {
        margin-top: 0px;
        background-size: cover;
        /* height: 820px; */
        background-image: none;
        padding-top: 20px;
        height: 100%;
    }
    @media screen and (max-width: 1600px) {
        padding-top: 100px;
    }

`
export const HeroImage =styled.img`
    width: 100vw;
    margin-top: 100px;
    background-size: cover;
    display: none;
     @media screen and (max-width: 670px) {
        width: 100vw;
        margin-top: 20px;
       margin-bottom: 20px;
       display: flex;
    }
   
`

export const HeroBg = styled.div`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
    width: 100%;
    height: 100%;

`

export const HeroCon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;

    @media screen and (max-width: 670px) {
        margin-top: 0px;
        margin-left: 0px;
        padding-top: 0px;
    }
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const VideoComp = styled.video`

    width: 60vw;
    @media screen and (max-width: 1000px) {
       width: 100vw;
    }
    @media screen and (max-width: 670px) {
       width: 100%;
      
    }
`
export const ImageBg = styled.div`
    background-position: 0;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(${BgNew}); */
    background-position: bottom;
    width: 100%;
    background-color: #090E13;
  


    @media screen and (min-width: 801px) {
        background-attachment: fixed;
    }
    @media screen and (max-width: 800px) {
        width: 100vw;
        height: 100%;
    }
    @media screen and (max-width: 670px) {
        position: fixed;
        width: 100vw;
        height: 100vh;
        /* background-image: url(${BgMobile}); */
    }
`

export const ContentAbsoluteCont = styled.div`
    height: 100vh;
    width: 100vw;

    @media screen and (max-width: 670px) {
        position: absolute;

        }
`
export const HeroContent = styled.div`
    /* max-width: 1200px; */
    /* background-position: 0;
    background-repeat: no-repeat;
    background-size: contain; */
    /* background-image: url(${BgFighterz}); */
   // background-image: url(${BattleBg});
    /* background-position: bottom; */
    padding: 0px 0px;
    display: flex;
    

    flex-direction: column;
    align-items: center;
   
    
    
    @media screen and (max-width: 670px) and (max-width: 768px) {
       align-items: center;
       justify-content: flex-start;
       margin-bottom: 00px;
    

    }
`

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 34px;
    text-align: center;
    font-family: "content";


    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 0px;
    color: #fff;
    font-size: 32px;
    text-align: center;
    max-width: 600px;
    font-family: 'content';

    @media screen and (max-width: 1400px) {
        font-size: 24px;
        margin-top: 0px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-left: 100px;
`

export const LogoHero = styled.img`
    width: 700px;
    margin-left: 3px;
    margin-top: 60px;
    
    /* max-width: 650px; */
    @media screen and (min-width: 700px)  and (max-width: 1400px) {
        width: 50%;
        margin-top: 00px;
    }

    @media screen and (max-width: 670px) {
        width: 50%;
        align-self: center;
        margin-top: 10px;
    }
`
export const CrystalHero = styled.img`
    width: 200px;
    height: 250px;

  
`