import React, { useState, useEffect } from 'react'
import {
    FighterTypesContainer2, StatTitle, ClassInfoContainer, Mock, MockParentContainer, StatRangeContainer, StatRangeIndividualCont, Summary, StatLabel, ClassLabel, StatFont, StatRangeCont, LoreFont, SecondRowCont, TypeChartContainer2,
} from './FighterTypesElements';

import AssasinMock from '../../images/Assasin_Mock.png'
import MonkMock from '../../images/Monk_Mock.png'
import DivineMock from '../../images/mockMarket6.png'
import SwordsmanMock from '../../images/mockMarket8.png'
import BeserkerMock from '../../images/mockMarket7.png'
import ArcherMock from '../../images/Archer_Mock.png'
import AlchemistMock from '../../images/mockMarket9.png'
import CastorMock from '../../images/mockMarket5.png'
import GrapplerMock from '../../images/mockMarket10.png'
import { ArwesThemeProvider, FrameUnderline, Button, } from '@arwes/core'
import HexTypes from './HexTypes';
import { Heading } from '../ContentSection/ContentElements';
import { useMediaQuery, } from 'react-responsive'
import HexTypes2 from './HexTypes2';
import HexTypes3 from './HexTypes3';
import { Heading2 } from './FighterInfoElements';

// import FadeInSection from '../FadeIn';

const FighterTypes = () => {
    const [selectedClass, setSelectedClass] = useState('Assasin')
    const [selectedMock, setSelectedMock] = useState(AssasinMock)
    const [strongStat, setStrongStat] = useState('SPD')
    const [neutralStat, setNeutralStat] = useState('ATK')
    const [weakStat, setWeakStat] = useState('DEF')
    const [classLore, setClassLore] = useState('Assassins attack from the shadows using their high speed to catch enemies off guard and make quick work of them. Their techniques in stealth and speed are their main advantage in battle.')

    useEffect(() => {

    }, []);

    //On Class logo click set new selected Class and call selectedClassData function
    const classClick = (logo) => {
        setSelectedClass(logo)
        selectedClassData(logo);
    }

    const selectedClassData = (logo) => {
        switch (logo) {
            case "Archer":
                setStrongStat('ATK');
                setNeutralStat('DEF');
                setWeakStat('SPD');
                setSelectedMock(ArcherMock);
                setClassLore('Archers take their time to deliver deadly strikes from a distance. These Fighterz attribute their high skill to years of practice and well calculated attacks. Their superb situational awareness makes Archers elite marksmen.');
                break;
            case "Assasin":
                setStrongStat('SPD');
                setNeutralStat('ATK');
                setWeakStat('DEF');
                setSelectedMock(AssasinMock);
                setClassLore('Assassins attack from the shadows using their high speed to catch enemies off guard and make quick work of them. Their techniques in stealth and speed are their main advantage in battle.');
                break;
            case "Swordsman":
                setStrongStat('DEF');
                setNeutralStat('SPD');
                setWeakStat('ATK');
                setSelectedMock(SwordsmanMock);
                setClassLore('Swordsmen are masters in the use of weapons and heavy armor to parry and shield attacks. They can form a nearly impenetrable defense and hold their ground against even the strongest opponents.');
                break;
            case "Alchemist":
                setStrongStat('ATK');
                setNeutralStat('DEF');
                setWeakStat('SPD');
                setSelectedMock(AlchemistMock);
                setClassLore('Alchemists use their studies of magic to perform incantations and craft magical items. Unlike Casters they use an outside energy source for their power and are able to amplify it to a devastating magnitude.');
                break;
            case "Caster":
                setStrongStat('SPD');
                setNeutralStat('ATK');
                setWeakStat('DEF');
                setSelectedMock(CastorMock);
                setClassLore('Casters use spells and magical abilities derived from their own natural aura. Conjuring magic from within they are able to cast very quickly. This, along with their diverse natural abilities makes them a daunting opponent.');
                break;
            case "Divine":
                setStrongStat('DEF');
                setNeutralStat('SPD');
                setWeakStat('ATK');
                setSelectedMock(DivineMock);
                setClassLore('Divine fighters utilize holy or demonic powers. Drawing from the supernatural they specialize in healing and defensive magic. They can very easily extend the duration of battles making them one of the most difficult to defeat.');
                break;
            case "Beserker":
                setStrongStat('ATK');
                setNeutralStat('DEF');
                setWeakStat('SPD');
                setSelectedMock(BeserkerMock);
                setClassLore('Berserkers are powerful melee fighters, their immense strength is augmented by their ferocious temperament. When angered Berserkers rampage indiscriminately and their fury makes them some of the strongest warriors.');
                break;
            case "Monk":
                setStrongStat('SPD');
                setNeutralStat('ATK');
                setWeakStat('DEF');
                setSelectedMock(MonkMock);
                setClassLore("Monks share an unyielding spirit and through many years of disciplined training they have mastered executing techniques at blinding speeds. Blessed with abilities of foresight they rapidly attack opponents with flurries of strikes.");
                break;
            case "Grappler":
                setStrongStat('DEF');
                setNeutralStat('SPD');
                setWeakStat('ATK');
                setSelectedMock(GrapplerMock);
                setClassLore('Grapplers are masters of counter attacks. They train diligently to perfect techniques that overwhelm even the largest opponents. Precise movements and manipulation of their bodies allow them to throw, trap, and submit their challengers.');
                break;
            default:
                break;
        }

    }

    /*
        React Responsiveness Media Queries
    */
    const isBigDevice = useMediaQuery({
        query: "(min-width: 951px)",
    });
    
    const isLapDevice = useMediaQuery(
        { minWidth: '631px',
        maxWidth: '1290px'}
    );

    // const isTabDevice = useMediaQuery({
    //     query: "(max-width: 830px)",
    // });

    const isMobileDevice = useMediaQuery({
        query: "(max-width: 630px)",
    });


    const palette = {
        primary: { main: '#70edfe' },
        secondary: { main: '#c466dc' },
        neutral: { main: '#001711' },
        text: {
            root: '#35efaa',
            headings: '#40ffce',
            link: '#c466dc',
            linkHover: '#d491fa'
        }
    };

    const themeSettings = { palette };


    return (
        <ArwesThemeProvider themeSettings={themeSettings}>
            {/* <FadeInSection> */}
            <FighterTypesContainer2 id={'classes'}>

                <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                    
                    <TypeChartContainer2 style={{height: '750px'}}>


                        <Heading2  >FighterZ Classes</Heading2>
                        <Summary style={{textAlign: 'left'}}> Click through the Logos below to get familiar with the different Classes.</Summary>
                        
                 


                        {/* {isLapDevice &&
                            <HexTypes2 whichClass={selectedClass} handleClass={(item) => classClick(item)} />

                    } */}
                        
                            {isBigDevice && <HexTypes whichClass={selectedClass} handleClass={(item) => classClick(item)} /> }
                            
                            {isMobileDevice && <HexTypes3 whichClass={selectedClass} handleClass={(item) => classClick(item)} />}    
                            <Button style={{ alignSelf: 'flex-end' , }} palette='secondary' FrameComponent={FrameUnderline} onClick={() => window.open('https://whitepaper.fusionfighterz.io/fighterz/fighterz-classes', '_blank')}>Learn More</Button>

                    </TypeChartContainer2>

                </div>



                <SecondRowCont>



                <ClassInfoContainer>
                            

                            <MockParentContainer>

                                <Mock src={selectedMock} />
                            </MockParentContainer>

                            {/* <StatRangeContainer>
                                <StatTitle>Base Stat Ranges</StatTitle>
                                <StatRangeCont>
                                    <StatRangeIndividualCont>
                                        <StatLabel>Strong Stat: </StatLabel>
                                        <StatFont style={{ color: 'green' }}>{strongStat}</StatFont>
                                    </StatRangeIndividualCont>

                                    <StatRangeIndividualCont>
                                        <StatLabel>Neutral Stat: </StatLabel>
                                        <StatFont>{neutralStat}</StatFont>
                                    </StatRangeIndividualCont>

                                    <StatRangeIndividualCont>
                                        <StatLabel>Weak Stat: </StatLabel>
                                        <StatFont style={{ color: 'red' }}>{weakStat}</StatFont>
                                    </StatRangeIndividualCont>
                                </StatRangeCont>


                            </StatRangeContainer> */}
                            <ClassLabel>{selectedClass}</ClassLabel>
                            <LoreFont>{classLore}</LoreFont>

                        </ClassInfoContainer>



                </SecondRowCont>



                </FighterTypesContainer2>
            
   
                {/* </FadeInSection> */}
           

        </ArwesThemeProvider>
    )
}

export default FighterTypes
