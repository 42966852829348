import styled from "styled-components";
import statPage from "../../images/statPage.png";

export const Container = styled.div`
  color: #fff;
  background-color: #090e13; //#757575 , #9bc4e2
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
`;
export const FirstContainer = styled.div`
  color: #fff;
  background-color: #757575; //#757575 , #9bc4e2
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  width: 100%;
`;
//well 
export const StatPageContainer = styled.div`
  color: #fff;
  background-color: #757575; //#757575 , #9bc4e2
  display: flex;
  background-image: url(${statPage});
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  height: 100%;
`;
export const HeaderContainer = styled.div`
  margin-top: 20px;
`;
export const ContentComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 900px;
`;

export const DashboardCont = styled.div`
  border: 2px #70edfe solid;
  width: 1400px;
  height: 800px;
  padding: 25px;
  border-radius: 5%;
  background-color: #202020;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 0 10px #9ecaed;
  margin-left: 10px;
`;

export const InfoCont = styled.div`
  border: 2px #70edfe solid;
  width: 100%;
  height: 100%;
  padding: 25px;
  border-radius: 5%;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px #9ecaed;
  margin-left: 10px;
`;
export const StatCont = styled.div`
  border: 2px #70edfe solid;
  width: 100%;
  height: 100%;
  padding: 25px;
  border-radius: 5%;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px #9ecaed;
  margin-left: 10px;
`;

export const MovesCont = styled.div`
  border: 2px #70edfe solid;
  width: 100%;
  height: 100%;
  padding: 25px;
  border-radius: 5%;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px #9ecaed;
  margin-left: 10px;
`;
export const ContentHeader = styled.h2`
  max-width: 440px;
  font-size: 62px;
  line-height: 24px;
  width: 100%;
  color: #fff;
  padding: 25px;
  align-self: center;
  justify-content: center;
  border-bottom: 2px #70edfe solid;
  font-family: "Custom";
`;
export const FighterImage = styled.img`
  width: 85%;
  margin: 10px;
  margin-bottom: 40px;
`;
export const MoveImage = styled.img`
  width: 45%;
  margin-bottom: 5px;
`;
export const HintImage = styled.img`
  width: 50%;
`;
export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;
export const FighterInfo = styled.h2`
  align-self: flex-start;
  font-size: 42px;
  font-family: "Custom";
  width: 50%;
`;

export const FighterInfo2 = styled.h2`
  align-self: flex-start;
  font-size: 42px;
  font-family: "Custom";
  margin-right: 200px;
  border-bottom: 2px #70edfe solid;
`;
export const FighterInfoItem = styled.h2`
  justify-self: center;
  font-size: 42px;

  font-family: "Custom";
`;
export const FighterInfoSubItem = styled.h2`
  font-size: 22px;
  font-family: "Content";
`;
export const FighterInfoSubItem2 = styled.h2`
  font-size: 22px;
  font-family: "Content";
  color: green;
`;

export const TopLine = styled.p`
  color: #fff;
  font-size: 26px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-family: "Custom";
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 68px;
  line-height: 1.1;
  font-weight: 600;
  color: #70edfe;

  font-family: "Custom";
`;
export const Column = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
`;

export const LeftCrystal = styled.img`
  width: 300px;
`;
export const LeftHalfCont = styled.div`
  position: absolute;

  animation-duration: 5s;
  animation-name: moveRight;
  @keyframes moveRight {
    from {
      left: 10%;
    }

    to {
      left: 40%;
    }
  }
`;

export const RightCrystal = styled.img`
  width: 300px;
`;

export const RightHalfCont = styled.div`
  position: absolute;
  animation-duration: 5s;
  animation-name: moveLeft;
  @keyframes moveLeft {
    from {
      right: 10%;
    }

    to {
      right: 40%;
    }
  }
`;

export const WholeCrystal = styled.img`
  width: 300px;
  margin-top: 300px;

  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
