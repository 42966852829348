import React from 'react'
import './Hex.scss'

import {   FirstContainer, } from './FighterInfoElements'
import {
    MeleeRow2,    TypeChart2, MagicRow2, WeaponRow2,
    } from './FighterTypesElements';
import AssasinLogo from '../../images/Assasin_Logo.png'
import SwordsmanLogo from '../../images/Swordsman_Logo.png'
import ArcherLogo from '../../images/Archer_Logo.png'
import CastorLogo from '../../images/Castor_Logo.png'
import DivineLogo from '../../images/Divine_Logo.png'
import AlchemistLogo from '../../images/Alchemist_Logo.png'
import BeserkerLogo from '../../images/Beserker_Logo.png'
import MonkLogo from '../../images/Monk_Logo.png'
import GrapplerLogo from '../../images/Grappler_Logo.png'
// import {useMediaQuery, } from 'react-responsive'
import HexSmall from './HexSmall';


const HexTypes = ({whichClass, handleClass}) => {

     /*
        React Responsiveness Media Queries
    */
        // const isLapDevice = useMediaQuery({
        //     query: "(max-width: 1290px)",
        // });
    


    return (
       

            <FirstContainer>
                
                                        
                        <TypeChart2>
                            <WeaponRow2>
                                {/* <TypeLabel >Weapon</TypeLabel> */}
                                
                            
                                    <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'column', justifyContent: 'center', }}>
                                        <HexSmall Image={ArcherLogo} Active={whichClass === 'Archer' ? true : false} handleClick={() => handleClass('Archer')}/>
                                        <HexSmall Image={AssasinLogo} Active={whichClass === 'Assasin' ? true : false} handleClick={() => handleClass('Assasin')}/>
                                        <HexSmall Image={SwordsmanLogo} Active={whichClass === 'Swordsman' ? true : false} handleClick={() => handleClass('Swordsman')}/>
                                    </div>
                               
                                    
                          
          
                        

                            </WeaponRow2>

                            <MagicRow2>
                                {/* <TypeLabel >Magic</TypeLabel> */}

           
                                        <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'column', justifyContent: 'center', }}>
                                            <HexSmall Image={AlchemistLogo} Active={whichClass === 'Alchemist' ? true : false} handleClick={() => handleClass('Alchemist')}/>
                                            <HexSmall Image={CastorLogo} Active={whichClass === 'Caster' ? true : false} handleClick={() => handleClass('Caster')}/>
                                            <HexSmall Image={DivineLogo} Active={whichClass === 'Divine' ? true : false} handleClick={() => handleClass('Divine')}/>
                                        </div>
                                  


                            </MagicRow2>

                            <MeleeRow2>
                                {/* <TypeLabel>Melee</TypeLabel> */}

                               
                                        <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'column', justifyContent: 'center', }}>
                                            <HexSmall Image={BeserkerLogo}  Active={whichClass === 'Beserker' ? true : false} handleClick={() => handleClass('Beserker')}/>
                                            <HexSmall Image={MonkLogo}  Active={whichClass === 'Monk' ? true : false} handleClick={() => handleClass('Monk')}/>
                                            <HexSmall Image={GrapplerLogo}  Active={whichClass === 'Grappler' ? true : false} handleClick={() => handleClass('Grappler')}/>     
                                        </div>
                                  
                                
                            </MeleeRow2>
                    
                            
                        </TypeChart2>
                    
                
            </FirstContainer>
      

    )
}

export default HexTypes
