import React from 'react'
import {   HeaderContainer,  Column,     ContainerAbout, ContentHeaderGS,  } from './ContentElements'

import "react-responsive-carousel/lib/styles/carousel.min.css"; //requires loader
import {useMediaQuery, } from 'react-responsive'

import {ArwesThemeProvider, }  from '@arwes/core'
import Carousel from './Quick2';
import FadeInSection from '../FadeIn';

const Gallery = () => {

    /*
        React Responsiveness Media Queries
    */
    // const isTabDevice = useMediaQuery({
    //     query: "(max-width: 830px)",
    // });

    const isMobileDevice = useMediaQuery({
        query: "(max-width: 620px)",
    });


    const palette = {
        primary: { main: '#70edfe' },
        secondary: { main: '#c466dc' },
        neutral: { main: '#001711' },
        text: {
          root: '#35efaa',
          headings: '#40ffce',
          link: '#c466dc',
          linkHover: '#d491fa'
        }
      };

    let themeSettings = {palette};

    return (
        <ArwesThemeProvider themeSettings={themeSettings}>
            <ContainerAbout id={'gallery'}>
                <HeaderContainer>
                    {/* <TopLine>Fusion FighterZ</TopLine> */}
                    <FadeInSection>
                        <ContentHeaderGS style={{marginBottom: '0px'}}>Gallery</ContentHeaderGS>
                    </FadeInSection>
                </HeaderContainer>

                
                    <Column>
                        {/* <Project header='Play to Earn'>

                            <ContentSummary>Fusion FighterZ is a NFT Battle Arena located in the Metaverse. With the rise of Play to Earn gaming people ought to own and be compensated for the value and effort they put into their games.</ContentSummary>
                            <ContentSummary> Enter the Fusion FighterZ Battle Arena to collect, fight, and earn!</ContentSummary>

                        </Project> */}

                        {isMobileDevice ? (
                                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px',}}>
                                   
                                      <FadeInSection>
                                           <Carousel reverse={false}/>
                                      </FadeInSection>
                                           <FadeInSection>
                                       <Carousel reverse={true}/>
                                       </FadeInSection>
   
                               </div>
                        ) : (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px',}}>
                                   
                                   <FadeInSection>
                                        <Carousel reverse={false}/>
                                   </FadeInSection>
                                        <FadeInSection>
                                    <Carousel reverse={true}/>
                                    </FadeInSection>

                            </div>
                                
                            

                        )}
                        
                    </Column>
                    
               
                
            </ContainerAbout>

        </ArwesThemeProvider>
       
    )
}

export default Gallery



//<ContentSummary>Fusion FighterZ is a NFT multiplayer battle arena within the Metaverse inspired by Axie Infinity and the Pokemon battle system, where players can earn tokens through skilled gameplay and participating in the ecosystem. Players can battle, improve, and fuse their NFT fighters for an immersive and lucrative gameplay experience. Using Blockchain economic design allows Fusion FighterZ to develop a Play-To-Earn gaming ecosystem. Anyone from anywhere can earn real financial rewards while having fun playing and mastering a strategy based multiplayer game.</ContentSummary>
                    