import React from 'react'
import { Container,ProgressLineCont, StartCircle, ProgressLineHorGrey,ObjectiveContainer,  HeaderContainer, Heading, RoadmapCard, RoadmapCardInner, RoadmapCardCont, ProgressLine, RoadmapSectionCont, ProgressLineHorCont, ProgressLineHor, ProgressStatusBox, ProgressStatusBoxGrey, ProgressLineVert, RoadmapCardTitle, RoadmapCardContColumn, EndCircle, ProgressVertLineCont, ObjectiveItem, ObjectiveCheckmarkDone, ObjectiveCheckmark, RoadmapSectionCont2, RoadmapCardCont2, RoadmapCardContColumn2, RoadmapCard2,  RoadmapContRow, ProgressStatusBox2, ProgressLineHor2, ProgressStatusBoxGrey2, ProgressLineHorGrey2, ProgressLineMobile } from './ContentElements'
import { useMediaQuery } from 'react-responsive'
import FadeInSection from '../FadeIn'


const Roadmap = () => {

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    return (
        
        <Container style={{marginTop: '100px',}} id={'roadmap'}>
            <HeaderContainer>
                {/* <TopLine>Fusion FighterZ</TopLine> */}
                <Heading>Roadmap</Heading>
            </HeaderContainer>

            {isTabletOrMobile ? 
            
            
                <RoadmapSectionCont2> 

                    <ProgressLineCont>
                        <StartCircle/>
                        <ProgressLineMobile/>
                        <EndCircle/>
                    </ProgressLineCont>

                    <RoadmapCardContColumn2>
                        <RoadmapCardCont2>
                            <RoadmapContRow>
                                <ProgressStatusBox2/>
                                <ProgressLineHor2/>
                                <FadeInSection style={{}}>
                                    <RoadmapCard2>
                                    <RoadmapCardTitle>Q1 2022</RoadmapCardTitle>
                                        <RoadmapCardInner>
                                            <ObjectiveContainer>
                                                <ObjectiveCheckmarkDone/>
                                                <ObjectiveItem>Genesis FighterZ Art finalized</ObjectiveItem>
                                            </ObjectiveContainer>

                                            <ObjectiveContainer>
                                                <ObjectiveCheckmarkDone/>
                                                <ObjectiveItem>Smart contract initialization</ObjectiveItem>
                                            </ObjectiveContainer>

                                            <ObjectiveContainer>
                                                <ObjectiveCheckmarkDone/>
                                                <ObjectiveItem>Game development begins</ObjectiveItem>
                                            </ObjectiveContainer>
                                        </RoadmapCardInner>
                                    </RoadmapCard2>
                                </FadeInSection>
                            </RoadmapContRow>
                            
                            <RoadmapContRow>
                                <ProgressStatusBox2/>
                                <ProgressLineHor2/>
                                <FadeInSection style={{}}>
                                    <RoadmapCard2>
                                        <RoadmapCardTitle>Q2 2022</RoadmapCardTitle>
                                        <RoadmapCardInner>
                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Release Fusion FighterZ Genesis Collection</ObjectiveItem>
                                            </ObjectiveContainer>
                    
                                            {/* <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Release Fusion FighterZ Origin Collection</ObjectiveItem>
                                            </ObjectiveContainer> */}

                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Release and airdrop Battle Moves Collection</ObjectiveItem>
                                            </ObjectiveContainer>

                                        </RoadmapCardInner>
                                    </RoadmapCard2>
                                </FadeInSection>
                            </RoadmapContRow>
                            

                            <RoadmapContRow>
                                <ProgressStatusBoxGrey2/>
                                <ProgressLineHorGrey2/>
                                <FadeInSection style={{}}>
                                    <RoadmapCard2>
                                    <RoadmapCardTitle>Q2 2022</RoadmapCardTitle>
                                        
                                        <RoadmapCardInner>

                                            

                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Token IDO and airdrop </ObjectiveItem>
                                            </ObjectiveContainer>

                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Launch Battle Arena game on Web platform</ObjectiveItem>
                                            </ObjectiveContainer>

                                        </RoadmapCardInner>
                                    </RoadmapCard2>
                                 </FadeInSection>
                            </RoadmapContRow>
                            

                            {/* <RoadmapContRow>
                                <ProgressStatusBoxGrey2/>
                                <ProgressLineHorGrey2/>
                                <FadeInSection style={{}}>
                                    <RoadmapCard2>
                                        <RoadmapCardTitle>Q2 2022 (Cont)</RoadmapCardTitle>   
                                        <RoadmapCardInner>
                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Game launch on mobile app iOS and Android </ObjectiveItem>
                                            </ObjectiveContainer>

                            

                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Release Series 2 Battle Moves</ObjectiveItem>
                                            </ObjectiveContainer>
                                        </RoadmapCardInner>
                                    </RoadmapCard2>
                                </FadeInSection>
                            </RoadmapContRow> */}
                            
                            <RoadmapContRow>
                                <ProgressStatusBoxGrey2/>
                                <ProgressLineHorGrey2/>
                                <FadeInSection style={{}}>
                                    <RoadmapCard2>
                                    <RoadmapCardTitle>Q3 2022</RoadmapCardTitle>
                                        <RoadmapCardInner>
                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Release Land Collection (Gyms)</ObjectiveItem>
                                            </ObjectiveContainer>
                                            {/* <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Release of Fight License to allow other NFT projects to enter the game </ObjectiveItem>
                                            </ObjectiveContainer> */}
                                            
                                            
                                        </RoadmapCardInner>
                                    </RoadmapCard2>
                                </FadeInSection>
                            </RoadmapContRow>
                            

                            <RoadmapContRow>
                                <ProgressStatusBoxGrey2/>
                                <ProgressLineHorGrey2/>
                                <FadeInSection style={{}}>
                                    <RoadmapCard2>
                                    <RoadmapCardTitle>Q4 2022</RoadmapCardTitle>
                                        <RoadmapCardInner>
                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Full Metaverse integration turning FighterZ into 3D Models</ObjectiveItem>
                                            </ObjectiveContainer>
                                            <ObjectiveContainer>
                                                <ObjectiveCheckmark/>
                                                <ObjectiveItem>Development of 3D MMO style Fighting game into ecosystem</ObjectiveItem>
                                            </ObjectiveContainer>
                                        </RoadmapCardInner>
                                    </RoadmapCard2>
                                </FadeInSection>
                            </RoadmapContRow>
                            

                        </RoadmapCardCont2>

                    </RoadmapCardContColumn2>
                    

                    
                </RoadmapSectionCont2>
                
                :

                <RoadmapSectionCont>
                {/* <ProgressLineCont>
                    <ProgressLine></ProgressLine>
                    <ProgressLine></ProgressLine>
                    <ProgressLine></ProgressLine>
                    <ProgressLine></ProgressLine>
                    <ProgressLine></ProgressLine>
                    <ProgressLine></ProgressLine>
                </ProgressLineCont> */}
                <ProgressLineHorCont>
                    <StartCircle/>
                    <ProgressLineHor/>
                    <ProgressLineHor/>
                    <ProgressLineHor/>
                    
                    <ProgressLineHorGrey/>
                    <ProgressLineHorGrey/>
                    <ProgressLineHorGrey/>
                    <ProgressLineHorGrey/>
                </ProgressLineHorCont>

                <FadeInSection style={{}}>
                <RoadmapCardContColumn>

                    <RoadmapCardCont>
                        <ProgressLineCont>
                            <ProgressStatusBox/>
                            <ProgressLine/>
                           
                                <RoadmapCard>
                                    <RoadmapCardTitle>Q1 2022</RoadmapCardTitle>
                                    <RoadmapCardInner>
                                        <ObjectiveContainer>
                                            <ObjectiveCheckmarkDone/>
                                            <ObjectiveItem>Genesis FighterZ Art finalized </ObjectiveItem>
                                        </ObjectiveContainer>

                                        <ObjectiveContainer>
                                            <ObjectiveCheckmarkDone/>
                                            <ObjectiveItem>Smart contract initialization</ObjectiveItem>
                                        </ObjectiveContainer>

                                        <ObjectiveContainer>
                                            <ObjectiveCheckmarkDone/>
                                            <ObjectiveItem>Game development begins</ObjectiveItem>
                                        </ObjectiveContainer>
                                    </RoadmapCardInner>
                                </RoadmapCard>
                        
                            
                        </ProgressLineCont>

                        <ProgressLineCont>
                            <ProgressStatusBox/>
                            <ProgressLine/>
                            
                                <RoadmapCard>
                                <RoadmapCardTitle>Q2 2022</RoadmapCardTitle>
                                    <RoadmapCardInner>
                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Release Fusion FighterZ Genesis Collection</ObjectiveItem>
                                        </ObjectiveContainer>
                
                                        {/* <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Release Fusion FighterZ Origin Collection</ObjectiveItem>
                                        </ObjectiveContainer> */}

                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Release and airdrop Battle Moves Collection</ObjectiveItem>
                                        </ObjectiveContainer>

                                        

                                        

                                    </RoadmapCardInner>
                                </RoadmapCard>
                            
                        </ProgressLineCont>

                        <ProgressLineCont>
                            <ProgressStatusBoxGrey/>
                            <ProgressLine></ProgressLine>
                            
                                <RoadmapCard>
                                <RoadmapCardTitle>Q2 2022</RoadmapCardTitle>
                                    
                                    <RoadmapCardInner>

                                        

                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Token airdrop </ObjectiveItem>
                                        </ObjectiveContainer>

                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Launch game</ObjectiveItem>
                                        </ObjectiveContainer>

                                    </RoadmapCardInner>
                                </RoadmapCard>
                            
                        </ProgressLineCont>

                        <ProgressVertLineCont>
                            <ProgressLineVert/>
                            
                        </ProgressVertLineCont>

                        


                    </RoadmapCardCont>


                        <ProgressLineHorCont>
                            <EndCircle/>
                            <ProgressLineHorGrey/>
                            <ProgressLineHorGrey/>
                            <ProgressLineHorGrey/>
                            
                            <ProgressLineHorGrey/>
                            <ProgressLineHorGrey/>
                            <ProgressLineHorGrey/>
                            <ProgressLineHorGrey/>
                        </ProgressLineHorCont>


                    <RoadmapCardCont>
                        

                        <ProgressLineCont>
                            <ProgressStatusBoxGrey/>
                            <ProgressLine/>
                           
                                <RoadmapCard>
                                <RoadmapCardTitle>Q4 2022</RoadmapCardTitle>
                                    <RoadmapCardInner>
                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Release Land Collection (Gyms)</ObjectiveItem>
                                        </ObjectiveContainer>
                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Launch of Fight City Metaverse</ObjectiveItem>
                                        </ObjectiveContainer>
                                        
                                        
                                    </RoadmapCardInner>
                                </RoadmapCard>
                            
                        </ProgressLineCont>

                        <ProgressLineCont>
                            <ProgressStatusBoxGrey/>
                            <ProgressLine></ProgressLine>
                            
                                <RoadmapCard>
                                <RoadmapCardTitle>Q3 2022</RoadmapCardTitle>   
                                    <RoadmapCardInner>
                                        {/* <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Release of Fight License to allow other NFT projects to enter the game</ObjectiveItem>
                                        </ObjectiveContainer> */}

                        

                                        <ObjectiveContainer>
                                            <ObjectiveCheckmark/>
                                            <ObjectiveItem>Release Series 2 Battle Moves</ObjectiveItem>
                                        </ObjectiveContainer>
                                    </RoadmapCardInner>
                                </RoadmapCard>
                           
                        </ProgressLineCont>

                        


                    </RoadmapCardCont>


                </RoadmapCardContColumn>
                
                </FadeInSection>
                
             
                </RoadmapSectionCont>

            }

            
            
        </Container>
    )
}

export default Roadmap
