import React from 'react'
import { ButtonCont, OfferingContainer, OffHeader, OffImage, OffImageCont, OffSummary, OffSummaryCont } from './OfferingElements'
// import { loadFull } from "tsparticles";
import { ArwesThemeProvider, FrameUnderline, Button, } from '@arwes/core'

/**
 * 
 * ImageStart
 * ImageFile
 * Summary
 * Header
 */

const Offering = ({Summary, Header, Img, ImgFirst, OffId, Wp}) => {
    // const particlesInit = async (main) => {
    //     console.log(main);
    
    //     // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    //     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    //     // starting from v2 you can add only the features you need reducing the bundle size
    //     await loadFull(main);
    //   };
    
      // const particlesLoaded = (container) => {
      //   console.log(container);
      // };

      const palette = {
        primary: { main: '#70edfe' },
        secondary: { main: '#c466dc' },
        neutral: { main: '#001711' },
        text: {
          root: '#35efaa',
          headings: '#40ffce',
          link: '#c466dc',
          linkHover: '#d491fa'
        }
      };

    let themeSettings = {palette};

    let wpURL = 'https://whitepaper.fusionfighterz.io/'

  
    return (
    <ArwesThemeProvider themeSettings={themeSettings}>
      
   

            {ImgFirst ? (
            <OfferingContainer>
                <OffImageCont>
                    <OffImage src={Img}/>
                </OffImageCont>

                <OffSummaryCont>
                    <OffHeader>{Header}</OffHeader>
                    <OffSummary>{Summary}</OffSummary>
                    <ButtonCont>
                      <Button palette='primary' FrameComponent={FrameUnderline} onClick={() => window.open(Wp, '_blank')}>Learn More</Button>

                    </ButtonCont>
                    
                </OffSummaryCont>
            </OfferingContainer>
            ) : (
            <OfferingContainer>
               

                <OffSummaryCont>
                    <OffHeader>{Header}</OffHeader>
                    <OffSummary>{Summary}</OffSummary>
                    <Button style={{ alignSelf: 'flex-end', marginTop: '10px', }} palette='primary' FrameComponent={FrameUnderline} onClick={() => window.open(Wp, '_blank')}>Learn More</Button>

                </OffSummaryCont>

                <OffImageCont>
                    <OffImage src={Img}/>
                </OffImageCont>
            </OfferingContainer>

            
            )}
           

        
    </ArwesThemeProvider>
  )
}

export default Offering