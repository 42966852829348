import styled from 'styled-components';

export const FighterTypesContainer = styled.div`
    display: flex;
    flex-direction: row;

    padding: 30px 30px;
    height: 1000px;
    margin: 100px;

    @media screen and (max-width: 1465px) {
        padding: 100px 10px;
        margin: 30px;
        
    }
    @media screen and (max-width: 1290px) {
        flex-direction: column;
        align-items: center;
        
        padding: 100px 10px;
        margin: 10px;
        margin-bottom: 200px;

    }
 
`
export const FighterTypesContainer2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 50px;

    margin-bottom: 150px;
    
  
 
`
export const SecondRowCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  


    @media screen and (min-width: 1600px) {
        /* height: 860px; */
        
    }
    @media screen and (max-width: 1290px) {
        flex-direction: column;
        align-items: center;
    }
`

export const TypeChartContainer = styled.div`
   border: 2px #70edfe solid;
   box-shadow: 0 0 10px #9ecaed;
   background: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px;
    height: 100%;
    width: 50%;
    border-radius: 5%;
    margin-right: 20px;

    @media screen and (max-width: 1290px) {
        height: 400px;
        padding: 5px 5px;
        margin-right: 0px;
        margin-bottom: 30px;
    }
 
`

export const TypeChartContainer2 = styled.div`
   border: 2px #70edfe solid;
   box-shadow: 0 0 10px #9ecaed;
   background: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 30px;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    width: 500px;
    max-width: 1000px;
    border-radius: 0%;

    @media screen and (max-width: 1290px) {
       
    }

    @media screen and (max-width: 630px) {
        height: 200px;
        padding: 5px 5px;
        margin-right: 0px;
        margin-bottom: 3px;
        border-radius: 5%;
        width: 380px;
    }
 
 
`
export const TypeChart = styled.div`
    display: flex;
    flex-direction: column;
    /* border: 2px #70edfe solid;
    box-shadow: 0 0 10px #9ecaed; */
    width: 100%;
    border-radius: 10%;
    overflow: hidden;
`
export const TypeChart2 = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #121212;
    justify-content: space-around;
    /* border: 2px #70edfe solid;
    box-shadow: 0 0 10px #9ecaed; */
    width: 100%;
    height: 100%;
    padding: 10px;
   
   @media screen and (max-width: 1465px) {

        
    }
    @media screen and (max-width: 1290px) {
       
    }
    @media screen and (max-width: 630px) {
        width: 375px;
        padding: 0px;
    }

`
export const Chart = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px #70edfe solid;
    box-shadow: 0 0 10px #9ecaed;
    border-radius: 10%;
    overflow: hidden;
`

export const ClassInfoContainer = styled.div`
    border: 3px #70edfe solid;
    box-shadow: 0 0 10px #9ecaed;
    background: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 30px;
    height: 750px;
    width: 440px;
    margin-left: 100px;
    

    @media screen and (max-width: 1290px) {
    
      
    }

    @media screen and (max-width: 630px) {
        margin-top: 5px;
        padding: 5px 5px;

        flex-direction: column;
        width: 375px;
    }
 
`
export const LabelRow = styled.div`
  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 100px;
    width: 100%;
`

export const WeaponRow = styled.div`
    /* background: #202020; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 0px;
    
    width: 100%;
    border-bottom: 2px #70edfe solid;
`
export const WeaponRow2 = styled.div`
    /* background: #202020; */
    display: flex;
    flex-direction: column;
    
   

    margin-bottom: 25px;
    padding-bottom: 10px;


    @media screen and (max-width: 1465px) {

        
    }
    @media screen and (max-width: 1290px) {
      
    }
`

export const WeaponRowCont = styled.div`
    background: #202020;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 200px;
    width: 100%;
`
export const MeleeRow = styled.div`
    //background: navy;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0px;
    height: 33%;

`

export const MeleeRow2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    @media screen and (max-width: 1465px) {

        
    }
    @media screen and (max-width: 1290px) {
        height: 200px;
        align-items: flex-start;
        justify-content: space-between;

    }
    
`
export const MagicRow = styled.div`
    //background: purple;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px 0px;
    height: 33%;
    border-bottom: 2px #70edfe solid;
`
export const MagicRow2 = styled.div`
    //background: purple;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-bottom: 25px;
   

    @media screen and (max-width: 1465px) {

        
    }
    @media screen and (max-width: 1290px) {
        height: 200px;
        align-items: flex-start;
        justify-content: space-between;

    }
`

export const Img = styled.img`
    width: 8rem;
    border-radius: 50%;
    margin-left: 10px;
    margin-bottom: 5px;
    
    @media screen and (max-width: 1290px) {
        width: 3.5rem;
        margin-right: 5px;
    }
 
   `

export const Mock = styled.img`
    width: 400px;
    height: 400px;
    margin-bottom: 15px;

    @media screen and (max-width: 1290px) {
        width: 300px;
        height: 300px;
    }
 
    
`
export const MockParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1290px) {
        padding-left: 25px;
    }
    @media screen and (max-width: 630px) {
        padding-left: 0px;
    }
  
`
export const StatRangeContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;


    @media screen and (max-width: 630px) {
        width: 90%;
    }
`
export const StatRangeIndividualCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 630px) {
        
        justify-content: space-between;
    }
`
export const StatRangeCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 370px;
    border-bottom: 5px #70edfe solid;
    padding-bottom: 10px;
 
    @media screen and (max-width: 1290px) {
        margin-bottom: 5px;
        border-bottom: 3px #70edfe solid;
    }
    @media screen and (max-width: 700px) {
       width: 90%;
    }
`

export const Title = styled.h1`
    align-self: center;
    color: #70edfe;
    font-family: "Custom";
    font-size: 62px;

    @media screen and (max-width: 1200px) {
        font-size: 34px;
    }
 
`

export const Summary = styled.p`
    align-self: center;
    margin: 20px;
    color: #fff;
    font-family: "Content";
    font-size: 18px;

    @media screen and (max-width: 1290px) {
        font-size: 16px;
        margin: 10px;
    }
    @media screen and (max-width: 630px) {
        font-size: 14px;
    }
 
`
export const Link = styled.a`
    color: #70edfe;
    font-family: "Custom";
    font-size: 26px;
`
export const ClassLabel = styled.h1`
    color: #fff;
    font-family: "Custom";
    font-size: 42px;

    @media screen and (max-width: 1290px) {
        font-size: 34px;
        margin: 0px;
    }
 
`
export const StatTitle = styled.h2`
    color: #fff;
    font-family: "Content";
    font-size: 32px;
    margin: 10px;

    @media screen and (max-width: 1290px) {
        font-size: 21px;
        margin: 0px;
    }
    @media screen and (max-width: 630px) {
        font-size: 18px;
    }
 
`

export const TypeLabel = styled.h2`
    color: #fff;
    font-family: "Custom";
    font-size: 42px;
    margin-top: 10px;
    margin-bottom: 5px;
    border-bottom: 2px #70edfe solid;


    @media screen and (max-width: 1465px) {

        
    }
    @media screen and (max-width: 1290px) {
        font-size: 28px;
        align-self: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`
export const StatLabel = styled.h3`
    color: #fff;
    font-family: "Content";
    font-size: 18px;
    margin-right: 5px;


    @media screen and (max-width: 1290px) {
        font-size: 18px;
        margin-right: 3px;

    }
 
    @media screen and (max-width: 630px) {
        font-size: 14px;
        margin-left: 3px;
        width: 50px;
    }
`
export const StatFont = styled.h3`
    color: #d3d3d3;
    font-family: "Custom";
    font-size: 24px;
    margin-left: 10px;
    margin-right: 10px;

    @media screen and (max-width: 1290px) {
        margin-left: 3px;
        margin-right: 3px;
        font-size: 18px;
    }
    @media screen and (max-width: 630px) {
        
        margin-left: 0px;
    }
 
`
export const LoreFont = styled.p`
    color: #fff;
    font-family: "Content";
    font-size: 18px;
    padding: 10px;
    width: 90%;
    @media screen and (max-width: 1290px) {
        font-size: 16px;
        margin-left: 20px;
        margin-bottom: 5px;
        
    }
    /* @media screen and (min-width: 1290px) {
        margin-top: 20px;
        font-size: 24px;
    } */
`
export const MobileArrowIcon = styled.div`

    @media screen and (max-width: 1290px){
       
    }
`;

