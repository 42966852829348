import React, { useState } from "react";
import ContentSection from "../components/ContentSection";
// import FighterTypes from '../components/FighterTypes/NewDesign';
import HeroSection from "../components/HeroSection";
import {
  ContentAbsoluteCont,
  ImageBg,
} from "../components/HeroSection/HeroElements";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useMediaQuery } from "react-responsive";

// import { loadFull } from "tsparticles";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  /*
        React Responsiveness Media Queries
    */
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 670px)",
  });

  // const isTabletDevice = useMediaQuery({
  //     query: "(max-device-width: 1024px)",
  // });

  // const isDesktop = useMediaQuery({
  //     query: "(max-device-width: 1200px)",
  // });

  // const isBigScreen = useMediaQuery({
  //     query: "(min-device-width: 1201px )",
  // });

  // const particlesInit = async (main) => {
  //     console.log(main);

  //     // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  //     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //     // starting from v2 you can add only the features you need reducing the bundle size
  //     await loadFull(main);
  //   };

  //   const particlesLoaded = (container) => {
  //     console.log(container);
  //   };

  return (
    <div style={{ width: "100vw" }}>
      {isMobileDevice ? (
        <div>
          <ImageBg />

          <ContentAbsoluteCont>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            <ContentSection Type={"Quick"} />
            <ContentSection Type={"GameSummary"} />
            <ContentSection Type={"Gallery"} />
            <ContentSection Type={"Liscence"} />
            <ContentSection Type={"QuickSum"} />

            {/* <ContentSection Type={'FeatureSet'}/>
                        <ContentSection Type={'P2E'}/> */}
            {/* <FighterTypes/> */}
            {/* <ContentSection Type={'Team'}/> */}
            <ContentSection Type={"Roadmap"} />
            <ContentSection Type={"Footer"} />
          </ContentAbsoluteCont>
        </div>
      ) : (
        <ImageBg>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle} />
          <HeroSection />
          <div>
            {/* <Particles //style={{marginTop: '1000px'}}
                        id='tsparticles'
                        init={particlesInit}
                        loaded={particlesLoaded}
                        
                            options={{
                                
                                fpsLimit: 50,
                                interactivity: {
                                events: {
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                },
                                modes: {
                                    bubble: {
                                    distance: 50,
                                    duration: 2,
                                    opacity: 0.8,
                                    size: 20,
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                    distance: 50,
                                    duration: 0.4,
                                    },
                                },
                                },
                                particles: {
                            
                                links: {
                                    color: "#FCC625",
                                    distance: 150,
                                    enable: false,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 1,
                                    straight: false,
                                },
                                number: {
                                    density: {
                                    enable: true,
                                    area: 1000,
                                    },
                                    value: 15,
                                },
                                opacity: {
                                    value: 0.5,
                                    anim: {
                                        enable: true
                                    }
                                },
                                shape: {
                                    type: "image",
                                    'image': {
                                        src: "/favicon-32x32.png",
                                        width: 100,
                                        height: 100,
                                    }
                                },
                                size: {
                                    value: 30,
                                        random: true,
                                        anim: {
                                            enable: true,
                                            speed: 3
                                        }
                                },
                                },
                            
                                detectRetina: true,
                            }}
                        /> */}

            <ContentSection Type={"Quick"} />

            <ContentSection Type={"GameSummary"} />
            <ContentSection Type={"Gallery"} />
            <ContentSection Type={"Liscence"} />
            <ContentSection Type={"QuickSum"} />

            {/*                     
                    <ContentSection Type={'FeatureSet'}/>
                    <ContentSection Type={'P2E'}/>
                    <FighterTypes/> */}
            {/* <ContentSection Type={"Team"} /> */}
            <ContentSection Type={"Roadmap"} />
          </div>

          <ContentSection Type={"Footer"} />
        </ImageBg>
      )}
    </div>
  );
};

export default Home;
