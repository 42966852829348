import React from 'react'
import FeatureSet from './FeatureSet'

import Roadmap from './Roadmap'
import Footer from './Footer'

import Other from './Other'
import GameSummary2 from './GameSummary2'
import MintSection2 from './MintSection2'
import Quick from './Quick'
import Quick2 from './Quick2'
import QuickSum from './QuickSummary';
import Gallery from './Gallery'
import Liscence from './Liscence'
import Team from './Team'

const ContentSection = ({Type}) => {

    switch(Type) {
        case ('Mint'):
            return <MintSection2/>
        case ('GameSummary'):
            return <GameSummary2/>
        case ('Quick'):
            return <Quick/>
        case ('Quick2'):
            return <Quick2/>
        case ('QuickSum'):
            return <QuickSum/>
        case ('Gallery'):
            return <Gallery/>
        case ('Liscence'):
            return <Liscence/>
        case ('FeatureSet'):
            return <FeatureSet/>
        case ('Team'):
            return <Team/>
        case ('Roadmap'):
            return <Roadmap/>
        case ('Other'):
            return <Other/>
        case ('Footer'):
            return <Footer/>
        default:
            break;
    }

    return (
        <div>
            

        </div>
    )
}

export default ContentSection