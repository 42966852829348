import React, {useState} from 'react'
import './Hex.scss'
import {   FirstContainer, } from './FighterInfoElements'
import {
     TypeLabel,   TypeChart2, WeaponRow2, MobileArrowIcon,
    } from './FighterTypesElements';
import AssasinLogo from '../../images/Assasin_Logo.png'
import SwordsmanLogo from '../../images/Swordsman_Logo.png'
import ArcherLogo from '../../images/Archer_Logo.png'
import CastorLogo from '../../images/Castor_Logo.png'
import DivineLogo from '../../images/Divine_Logo.png'
import AlchemistLogo from '../../images/Alchemist_Logo.png'
import BeserkerLogo from '../../images/Beserker_Logo.png'
import MonkLogo from '../../images/Monk_Logo.png'
import GrapplerLogo from '../../images/Grappler_Logo.png'
import HexMini from './HexMini';
import {FaAngleRight, FaAngleLeft} from 'react-icons/fa';

const HexTypes3 = ({whichClass, handleClass}) => {
    const [currentType, setCurrentType] = useState('weapon')
    const [typeCounter, setTypeCounter] = useState('1')
    
    //  /*
    //     React Responsiveness Media Queries
    // */
    //     const isLapDevice = useMediaQuery({
    //         query: "(max-width: 1290px)",
    //     });
        
    //     const isTabDevice = useMediaQuery({
    //         query: "(max-width: 830px)",
    //     });
    
    //     const isMobileDevice = useMediaQuery({
    //         query: "(max-width: 620px)",
    //     });
    


        const toggleArrow = (clickSide) => {
            if (clickSide === 'right'){
                switch(typeCounter){
                    case '1':
                        setCurrentType('magic')
                        setTypeCounter('2')
                        break;
                    case '2':
                        setCurrentType('melee')
                        setTypeCounter('3')
                        break;
                    case '3':
                        setCurrentType('weapon')
                        setTypeCounter('1')
                        break;
                    default:
                        break;
                }
                
            }
                else if (clickSide === 'left') {
                    switch(typeCounter){
                        case '1':
                            setCurrentType('melee')
                            setTypeCounter('3')
                            break;
                        case '2':
                            setCurrentType('weapon')
                            setTypeCounter('1')
                            break;
                        case '3':
                            setCurrentType('magic')
                            setTypeCounter('2')
                            break;
                        default:
                            break;
                    }
                    
                }
     
        }


    return (
       

            <FirstContainer>
                
                                        
                        <TypeChart2>
                            <WeaponRow2>
                                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', marginTop: '5px'}}>

                                    <MobileArrowIcon onClick={() => toggleArrow('left')}>
                                        <FaAngleLeft  style={{color: '#fff', fontSize: '42px', alignSelf: 'center'}}/>

                                    </MobileArrowIcon>
                                
                                {currentType === 'weapon' && <TypeLabel >Weapon</TypeLabel>}
                                {currentType === 'magic' && <TypeLabel >Magic</TypeLabel>}
                                {currentType === 'melee' && <TypeLabel >Melee</TypeLabel>}


                                    
                                
                                    <MobileArrowIcon onClick={() => toggleArrow('right')}>
                                        <FaAngleRight style={{color: '#fff', fontSize: '42px', alignSelf: 'center'}}/>
                                    </MobileArrowIcon>
                                </div>
                                
                                {currentType === 'weapon' && 
                                    <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row', justifyContent: 'center'}}>
                                        <HexMini Image={ArcherLogo} Active={whichClass === 'Archer' ? true : false} handleClick={() => handleClass('Archer')}/>
                                        <HexMini Image={AssasinLogo} Active={whichClass === 'Assasin' ? true : false} handleClick={() => handleClass('Assasin')}/>
                                        <HexMini Image={SwordsmanLogo} Active={whichClass === 'Swordsman' ? true : false} handleClick={() => handleClass('Swordsman')}/>
                                    </div>
                                }
                                {currentType === 'magic' && 
                                    <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row', justifyContent: 'center', }}>
                                        <HexMini Image={AlchemistLogo} Active={whichClass === 'Alchemist' ? true : false} handleClick={() => handleClass('Alchemist')}/>
                                        <HexMini Image={CastorLogo} Active={whichClass === 'Caster' ? true : false} handleClick={() => handleClass('Caster')}/>
                                        <HexMini Image={DivineLogo} Active={whichClass === 'Divine' ? true : false} handleClick={() => handleClass('Divine')}/>
                                    </div>
                                }
                                  {currentType === 'melee' && 
                                    <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row', justifyContent: 'center', }}>
                                        <HexMini Image={BeserkerLogo}  Active={whichClass === 'Beserker' ? true : false} handleClick={() => handleClass('Beserker')}/>
                                        <HexMini Image={MonkLogo}  Active={whichClass === 'Monk' ? true : false} handleClick={() => handleClass('Monk')}/>
                                        <HexMini Image={GrapplerLogo}  Active={whichClass === 'Grappler' ? true : false} handleClick={() => handleClass('Grappler')}/>     
                                    </div>
                                }
                                
                          
          
                        

                            </WeaponRow2>

                            {/* <MagicRow2>
                                <TypeLabel >Magic</TypeLabel>

                                {isLapDevice ? (
                                        <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row', justifyContent: 'center', }}>
                                            <HexSmall Image={AlchemistLogo} Active={whichClass === 'Alchemist' ? true : false} handleClick={() => handleClass('Alchemist')}/>
                                            <HexSmall Image={CastorLogo} Active={whichClass === 'Caster' ? true : false} handleClick={() => handleClass('Caster')}/>
                                            <HexSmall Image={DivineLogo} Active={whichClass === 'Divine' ? true : false} handleClick={() => handleClass('Divine')}/>
                                        </div>
                                    ) : (
                                    <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row'}}>
                                        <Hex Image={AlchemistLogo} Active={whichClass === 'Alchemist' ? true : false} handleClick={() => handleClass('Alchemist')}/>
                                        <Hex Image={CastorLogo} Active={whichClass === 'Caster' ? true : false} handleClick={() => handleClass('Caster')}/>
                                        <Hex Image={DivineLogo} Active={whichClass === 'Divine' ? true : false} handleClick={() => handleClass('Divine')}/>
                                    </div>
                                    )}


                            </MagicRow2>

                            <MeleeRow2>
                                <TypeLabel>Melee</TypeLabel>

                                {isLapDevice ? (
                                        <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row', justifyContent: 'center', }}>
                                            <HexSmall Image={BeserkerLogo}  Active={whichClass === 'Beserker' ? true : false} handleClick={() => handleClass('Beserker')}/>
                                            <HexSmall Image={MonkLogo}  Active={whichClass === 'Monk' ? true : false} handleClick={() => handleClass('Monk')}/>
                                            <HexSmall Image={GrapplerLogo}  Active={whichClass === 'Grappler' ? true : false} handleClick={() => handleClass('Grappler')}/>     
                                        </div>
                                    ) : (
                                    <div style={{display: 'flex', alignItems: 'space-around', flexDirection: 'row'}}>
                                        <Hex Image={BeserkerLogo}  Active={whichClass === 'Beserker' ? true : false} handleClick={() => handleClass('Beserker')}/>
                                        <Hex Image={MonkLogo}  Active={whichClass === 'Monk' ? true : false} handleClick={() => handleClass('Monk')}/>
                                        <Hex Image={GrapplerLogo}  Active={whichClass === 'Grappler' ? true : false} handleClick={() => handleClass('Grappler')}/>     
                                    </div>
                                    )}
                                
                            </MeleeRow2> */}
                    
                            
                        </TypeChart2>
                    
                
            </FirstContainer>
      

    )
}

export default HexTypes3
