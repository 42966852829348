import React from 'react'
import './Carousel.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css"; //requires loader



const Quick2 = ({reverse}) => {

    /*
        React Responsiveness Media Queries
    */
    // const isTabDevice = useMediaQuery({
    //     query: "(max-width: 830px)",
    // });

    // const isMobileDevice = useMediaQuery({
    //     query: "(max-width: 620px)",
    // });





    return (
        

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw'}}>
                {reverse ? (


                <div class="slider2">
                    <div class="slider-row2"></div>
                </div>
                ) : (

                <div class="slider">
                    <div class="slider-row"></div>
                </div>
                )}
   
                
            </div>
  
                                
    )
}

export default Quick2



//<ContentSummary>Fusion FighterZ is a NFT multiplayer battle arena within the Metaverse inspired by Axie Infinity and the Pokemon battle system, where players can earn tokens through skilled gameplay and participating in the ecosystem. Players can battle, improve, and fuse their NFT fighters for an immersive and lucrative gameplay experience. Using Blockchain economic design allows Fusion FighterZ to develop a Play-To-Earn gaming ecosystem. Anyone from anywhere can earn real financial rewards while having fun playing and mastering a strategy based multiplayer game.</ContentSummary>
                    