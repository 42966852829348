import styled from "styled-components";

export const Container = styled.div`
    color: #fff;
    background-color:  #757575;  //#757575 , #9bc4e2
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    width: 100%;

`
export const FirstContainer = styled.div`
    width: 100%;
    padding: 20px 20px;
    display: flex;
    align-items: center;

`

export const HexContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    height: 800px;
    margin-top: 80px;
    padding-top: 100px;
`
export const FirstContainer2 = styled.div`
    color: #fff;
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 110px;
    margin-right: -100px;
    margin-top: 50px;
`
export const FirstContainer3 = styled.div`
    color: #fff;
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;


`

export const FirstContainer4 = styled.div`
    color: #fff;
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 70px;

`
export const HeaderContainer = styled.div`
    margin-top: 20px;

`
export const ContentComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
   
`

export const TopLine = styled.p`
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
   
    font-size: 42px;
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    font-family: 'Custom';
   
`

export const Heading2 = styled.h1`
   
    font-size: 42px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;
    font-family: 'Custom';
   
`
export const Column = styled.div`
    
    background-color: #d3d3d3;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 200px;

`
export const Column2 = styled.div`

    background-color: #d3d3d3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    width: 270px;

`

export const Content = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color:  #000 ;
    font-family: 'Content';
`

export const Head = styled.h1`
    font-size: 108px;
    color: #fff;
    font-family: 'Custom';
`