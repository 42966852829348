import styled from "styled-components";
import BG from '../../images/a-neon-city.jpg'
import BG2 from '../../images/bckg1.jpg'
// import BG3 from '../../images/bg3.png'
import BG4 from '../../images/bg4.png'
import BgG from '../../images/backGrey.jpg'
// import Banner from '../../images/Twit_Banner1.png'
import BoardApe from '../../images/board.png'
import Offerings from '../../images/parallax.png'


export const Container = styled.div`

    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    
    /* background-image: url(${BG}); */
    width: 100%;
    
    @media screen and (max-width: 676px) {
        padding-top: 100px;
        background-color: #090E13;
    }

`
export const Container2 = styled.div`
   
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    height: 1100px;
    /* background-image: url(${BG2}); */
    width: 100%;
    margin-top: 100px;
    margin-bottom: 250px;

    @media screen and (max-width: 676px) {
        margin-bottom: 400px;
    }

`
export const Container3 = styled.div`
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    padding-top: 100px;

    background-image: url(${BgG});
    width: 100%;
    background-size: cover;
    margin-bottom: 350px;

`
export const Container4 = styled.div`
    
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 170px;
    
    /* background-image: url(${BG4}); */
    width: 100%;
    height: 1080px;

    @media screen and (max-width: 676px) {
        
        
    }

`

export const ContainerMint = styled.div`
    
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /* background-size: cover;
    background-repeat: no-repeat; */

    /* background-image: url(${BgG}); */
    /* width: 100%;
    height: 1080px; */
    @media screen and (max-width: 1150px) {
        flex-direction: column;
        align-items: center;
    }
    @media screen and (max-width: 670px) {
        flex-direction: column;
        align-items: center;
    }

`
export const ContainerOther = styled.div`
    
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    margin-top: 80px;
    padding-top: 0px;
    /* background-image: url(${BG4}); */
    width: 100%;
    height: 500px;

`

export const ContainerAbout = styled.div`
   
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    margin-top: 50px;
    /* background-image: url(${BG2}); */
    width: 100%;
    /* margin-top: 100px; */
 
    background-color: #090E13;
    @media screen and (max-width: 676px) {
        margin-bottom: 200px;
        margin-top: 100px;

    }

`
export const LisenceImage = styled.img`
    width: 800px;

    @media screen and (max-width: 676px) {
        width: 100vw;
        margin-top: 0px;
    }
`

export const AboutImage = styled.img`
    width: 1000px;

    @media screen and (max-width: 1400px) {
       width: 800px;
    }
    @media screen and (max-width: 1150px) {
       width: 600px;
    }
    @media screen and (max-width: 1000px) {
       width: 400px;
    }

    @media screen and (max-width: 676px) {
        width: 400px;
        margin-top: 100px;
    }
`

export const ContainerQuick = styled.div`
   
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
    height: 90vh;
    /* background-image: url(${BoardApe}); */
    width: 100%;
    margin-top: 100px;
    z-index: 3;
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #090E13;

    /* opacity: .8; */
    @media screen and (max-width: 670px) {
        margin-bottom: 0px;
        margin-top: 0px;
        background-position: top;
        align-items: center;
        justify-content: flex-end;
        height: 65vh;
        background-size: 80%;
        padding-right: 0px;
        padding-bottom: 50px;
        padding-top: 0px;
    }

`



export const ContainerTeam = styled.div`
   
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    height: 100vh;
    margin-bottom: 200px;
    width: 100%;
    margin-top: 100px;
    z-index: 3;
    
    /* background-size: 60%;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #090E13; */

    /* opacity: .8; */
    @media screen and (max-width: 676px) {
        margin-bottom: 400px;
    }
`

export const ContainerMint2 = styled.div`
   
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    height: 1000px;
    /* background-image: url(${BG2}); */
    width: 100%;
    /* margin-top: 100px; */
    z-index: 3;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #121212;
    /* opacity: .8; */
    @media screen and (max-width: 676px) {
        margin-bottom: 400px;
    }

`
export const ContainerAbout2 = styled.div`
   
   
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    /* background-image: url(${BG2}); */
    width: 100%;
    /* margin-top: 100px; */
 
    background-color: #090E13;
    @media screen and (max-width: 676px) {
        margin-top: 100px;
    }

`



export const ContainerOfferings = styled.div`
    z-index: -1;
    /* background-color:  #757575;  //#757575 , #9bc4e2 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    height: 100%;
    background-image: url(${Offerings});
    width: 100%;
    /* margin-top: 100px; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: left bottom; */
 
    border-bottom: 3px solid #fff;
    
    @media screen and (max-width: 670px) {
        margin-bottom: 0px;
       background-image: none;
       height: 3400px;
       padding-bottom: 0px;
       background-color: #090E13;
    }

`

export const FirstContainer = styled.div`
    color: #fff;
    background-color:  #757575;  //#757575 , #9bc4e2
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    width: 100%;

`
export const HeaderContainer = styled.div`
   

    @media screen and (max-width: 670px) {
        margin-top: 4px;
    }
`
export const ContentComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   

    @media screen and (max-width: 1030px) {
       flex-direction: column;
       align-items: center;
       justify-content: space-between;
       margin: 10px;
    }
    @media screen and (max-width: 870px) {
        margin: 0px;
    }
`
export const DoubleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    
    @media screen and (max-width: 1700px) {
       flex-direction: column;
    }
`
export const MintContComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0px;

    @media screen and (max-width: 870px) {
       flex-direction: column;
       align-items: center;
       justify-content: space-around;
       margin: 10px;
    }
    @media screen and (max-width: 670px) {
       margin: 0px;
    }
`

export const TopLine = styled.p`
    color: #fff;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 6px;
    margin-top: 10px;

    font-size: 52px;
    text-align: center;
    max-width: 600px;
    font-family: 'custom';

    @media screen and (max-width: 1370px) {
        font-size: 40px;
        margin-bottom: 4px;
    }
    
    @media screen and (max-width: 990px) {
        font-size: 24px;
        margin-bottom: 4px;
    }

    @media screen and (max-width: 670px) {
        font-size: 24px;
        margin-bottom: 0px;
    }
`

export const Heading = styled.h1`
    margin-bottom: 5px;
    font-size: 100px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff; //#70edfe;
    text-align: center;
    font-family: "Custom";

    @media screen and (max-width: 1370px) {
        font-size: 40px;
        margin-bottom: 4px;
    }

    @media screen and (max-width: 990px) {
        font-size: 40px;
        margin-bottom: 4px;
    }

    @media screen and (max-width: 670px) {
        font-size: 32px;
        margin-bottom: 0px;
    }

   
`
export const Column = styled.div`
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1020px) {
       margin: 0px;
    }
`
export const Column2 = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 1020px) {
       margin: 0px;
    }
`
export const Column3 = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1020px) {
       margin: 0px;
    }
`

export const Column4 = styled.div`
    margin-left: 200px;
    margin-right: 200px;
`

export const Content = styled.p`
 
    margin: 5px;
    height: 160px;
    font-size: 20px;
    text-align: left;
    color:  #fff ;
    font-family: "Content"; 
    /* font-size: 24px; */

    @media screen and (max-width: 1700px) {
        height: 150px;
        font-size: 18px;
    }
    @media screen and (max-width: 1030px) {
        height: 140px;
        font-size: 16px;
    }
    @media screen and (max-width: 670px) {
        height: 80px;
        font-size: 12px;
    }
`
export const MintContent = styled.p`
    max-width: 440px;
   padding-right: 30px;
  
    font-size: 24px;
    text-align: center;
    color:  #fff ;
    font-family: "Content"; 
    /* font-size: 24px; */

    @media screen and (max-width: 676px) {
        font-size: 12px;
        margin-top: 2px;
    }
`
export const ContentSummary = styled.p`
    max-width: 800px;
    margin-bottom: 5px;
    font-size: 30px;
    text-align: left;

    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */
    @media screen and (max-width: 1600px) {
       font-size: 26px;
    }

    @media screen and (max-width: 1500px) {
       font-size: 22px;
    }
    @media screen and (max-width: 1400px) {
       font-size: 18px;
    }
    @media screen and (max-width: 870px) {
        font-size: 14px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        margin-bottom: 25px;
        max-width: 300px;
        margin-top: 10px;
    }
`

export const ContentSummaryMint = styled.p`
    max-width: 800px;
    margin-bottom: 5px;
    font-size: 26px;
    text-align: center;
    align-self: center;
    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */
    @media screen and (max-width: 1600px) {
       font-size: 26px;
       max-width: 700px;
    }

    @media screen and (max-width: 1500px) {
       font-size: 22px;
       max-width: 650px;
    }
    @media screen and (max-width: 1400px) {
       font-size: 18px;
       max-width: 600px;
    }
    @media screen and (max-width: 1250px) {
       font-size: 18px;
       max-width: 500px;
    }
    @media screen and (max-width: 870px) {
        font-size: 14px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        margin-bottom: 25px;
        max-width: 300px;
        margin-top: 10px;
    }
`

export const ContentSummary2 = styled.p`
    max-width: 800px;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: left;

    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */

    @media screen and (max-width: 1400px) {
       font-size: 14px;
    }
    @media screen and (max-width: 870px) {
        font-size: 18px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        margin-bottom: 5px;
    }
`
export const ContentHeaderGS = styled.p`

    margin-bottom: 20px;
    margin-right: 50px;
    margin-top: 35px;
    font-size: 64px;
    text-align: center;
    color:  #fff ;
    font-family: 'Custom';
    /* font-family: 'custom';
    font-size: 34px; */

    @media screen and (max-width: 1400px) {
       font-size: 54px;
    }
    @media screen and (max-width: 1150px) {
       font-size: 44px;
    }
    @media screen and (max-width: 900px) {
        font-size: 32px;
    }
    @media screen and (max-width: 670px) {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 5px;
        width: 100%;
        align-self: center;
        margin-right: 0px;
        margin-left: 0px;
    }
`
export const ContentHeaderMint = styled.p`

    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 64px;
    text-align: center;
    color:  #fff ;
    font-family: 'Content';
    /* font-family: 'custom';
    font-size: 34px; */

    @media screen and (max-width: 1400px) {
       font-size: 14px;
    }
    @media screen and (max-width: 870px) {
        font-size: 18px;
    }
    @media screen and (max-width: 670px) {
        font-size: 14px;
        margin-bottom: 5px;
    }
`

export const ContentHeader = styled.h2`
    max-width: 440px;
    text-align: center;
    font-size: 62px;
    line-height: 24px;
    color:  #fff ;
    padding: 15px;
    border-bottom: 2px #70edfe solid;
    font-family: "Custom";
    
    @media screen and (max-width: 1030px) {
       font-size: 58px;
    }
    @media screen and (max-width: 670px) {
        padding: 4px;
        font-size: 32px;
    }
`

export const ContentFeatureCard = styled.div`
    border: 2px #70edfe solid;
    width: 320px;
    padding: 25px;
    border-radius: 10%;
    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    box-shadow: 0 0 10px #9ecaed;
    margin-left: 10px;

     @media screen and (max-width: 1400px) {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 20px;
    }
`
export const ContentSummaryCard = styled.div`
    border: 2px #70edfe solid;
    width: 500px;
    padding: 30px;
    border-radius: 10%;
    background-color: #303030;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 360px;
    box-shadow: 0 0 10px #9ecaed;
    margin-bottom: 10px;

    /* @media screen and (max-width: 870px) {
       width: 350px;
    } */
`
export const MintCard = styled.div`

  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    /* box-shadow: 0 0 10px #9ecaed; */

    @media screen and (max-width: 1020px) {
       width: 300px;
       margin: 0px;
       padding: 10px;
    }
`
export const FeatureImage = styled.img`
    width: 100%;
   

    border-radius: 5%;
`
export const GIFImage = styled.img`
    width: 600px;

    @media screen and (max-width: 1150px) {
        width: 400px;
    }

    @media screen and (max-width: 870px) {
        width: 300px;
    }

`
export const SummaryImage = styled.img`
    
   
   

    @media screen and (max-width: 870px) {
        width: 300px;
    }

    @media screen and (max-width: 400px) {
        width: 240px;
    }
`
export const SummaryImageCont = styled.div`
     width: 600px;
`

export const RoadmapCard = styled.div`
    width: 320px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 0%;
    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    box-shadow: 0 0 10px #9ecaed;
    border: 2px #70edfe solid;
`
export const RoadmapCard2 = styled.div`
    border: 2px #70edfe solid;
    width: 320px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 0%;
    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    box-shadow: 0 0 10px #9ecaed;

    @media screen and (max-width: 500px) {
        width: 250px;
        overflow:hidden;
        height: 100%;
    }
`

export const RoadmapCardInner = styled.div`
    border-top: 2px #70edfe solid;
    width: 310px;
    margin-top: 20px;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    
    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: space-evenly;
    padding: 20px;;
    height: 300px;

    @media screen and (max-width: 500px) {
        width: 240px;
        padding: 5px;
        margin-top: 5px;
        height: 100%;
    }
`
export const RoadmapCardTitle = styled.h2`
    margin-top: 10px;    
    font-size: 34px;
    color: #fff;

    @media screen and (max-width: 500px) {
        font-size: 24px;
    }
`

export const ProgressLineCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ProgressVertLineCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 95px;
`

export const RoadmapCardCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around
`

export const RoadmapCardCont2 = styled.div`
    display: flex;
    flex-direction: column;
`

export const RoadmapCardContColumn = styled.div`
    display: flex;
    flex-direction: column;
`
export const RoadmapCardContColumn2 = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    margin-top: 40px;
`
export const ProgressLine = styled.div`
    border-right: 6px solid #70edfe;
    height: 50px;
`
export const ProgressLine2 = styled.div`
    border-right: 6px solid #70edfe;
    height: 160px;
`

export const ProgressLineGrey2 = styled.div`
    border-right: 6px solid #d3d3d3;
    height: 160px;
`
export const ProgressLineMobile = styled.div`
    border-right: 6px solid #d3d3d3;
    height: 100%;
`

export const ProgressLineVert = styled.div`
    border-right: 6px solid #d3d3d3;
    height: 500px;
    margin-top: -23px;

`
export const RoadmapContRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 500px) {
        margin-bottom: 50px;
    }
`

export const RoadmapSectionCont = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px;
`
export const RoadmapSectionCont2 = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px;
`

export const ProgressLineHorCont = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -23px;
`

export const ProgressLineHor = styled.div`
    border-bottom: 6px solid #70edfe;
    width: 153px;
`
export const ProgressLineHor2 = styled.div`
    border-bottom: 6px solid #70edfe;
    width: 50px;
`
export const ProgressLineHorGrey = styled.div`
    border-bottom: 6px solid #d3d3d3;
    width: 155px;

`
export const ProgressLineHorGrey2 = styled.div`
    border-bottom: 6px solid #d3d3d3;
    width: 50px;

`

export const ProgressStatusBox = styled.div`
    border: 2px #70edfe solid;
    width: 40px;
    margin-top: -40px;
    background-color: #70edfe;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40px;
    box-shadow: 0 0 10px #9ecaed;
`
export const ProgressStatusBox2 = styled.div`
    border: 2px #70edfe solid;
    width: 40px;
    
    background-color: #70edfe;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40px;
    box-shadow: 0 0 10px #9ecaed;
`
export const ProgressStatusBoxGrey = styled.div`
    border: 2px #70edfe solid;
    width: 40px;
    margin-top: -40px;
    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40px;

`
export const ProgressStatusBoxGrey2 = styled.div`
    border: 2px #70edfe solid;
    width: 40px;

    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40px;

`
export const StartCircle =styled.div`
    border: 2px #70edfe solid;
    border-radius: 50%;
    width: 40px;
 
    background-color: #70edfe;
    height: 40px;
    box-shadow: 0 0 10px #9ecaed;
`

export const EndCircle =styled.div`
    border: 2px #70edfe solid;
    border-radius: 50%;
    width: 40px;
    margin-left: -5px;
 
    background-color: #202020;
    height: 40px;
    box-shadow: 0 0 10px #9ecaed;
`

export const ObjectiveItem = styled.p`
    font-size: 18px;
    margin-left: 20px;
    line-height: 24px;
    flex-grow: 1;
    color: #fff;
    font-family: 'Content';

    @media screen and (max-width: 500px) {
    font-size: 14px;
    }
`

export const ObjectiveCheckmark= styled.div`
    border: 2px #70edfe solid;
    border-radius: 50%;
    width: 20px ;
    background-color: #202020;
    height: 20px;
    box-shadow: 0 0 10px #9ecaed;
    flex-shrink: 0;
    margin-top: 5px;
`

export const ObjectiveCheckmarkDone= styled.div`
    border: 2px #70edfe solid;
    border-radius: 50%;
    width: 20px;
    background-color: #70edfe;
    height: 20px;
    box-shadow: 0 0 10px #9ecaed;
    flex-shrink: 0;
`

export const ObjectiveContainer = styled.div`
    display: flex;
    flex-direction: row;
  
    align-items: flex-start;
  

`

export const Diagram = styled.img`
    width: 100%;
    

    @media screen and (min-width: 1100px) {
        
    }
`
export const HexEarn = styled.img`
    width: 250px;
    box-shadow: 0 0 10px #9ecaed;
    border: 2px #70edfe solid;
`
export const HexEarnRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    justify-content: space-around;
`
export const HexEarnCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: space-around;
`

export const SubLogos = styled.img`
    width: 100px;
    margin-top: 25px;

    @media screen and (max-width: 1090px) {
      width: 60px;
      margin-top: 10px;
    }
`
export const SubLogo2 = styled.img`
    
    width: 100px;
    margin-top: 15px;

    @media screen and (max-width: 1090px) {
      width: 60px;
      margin-top: 10px;
    }
`
export const SubLogo3 = styled.img`
    
    width: 100px;
    margin-top: 35px;

    @media screen and (max-width: 1090px) {
      width: 60px;
      margin-top: 10px;
    }
`

export const OtherColCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1090px) {

      
    }
`
export const OtherRowCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 500px;
`

export const SummaryImage2 =styled.img`
    width: 100%;

    @media screen and (max-width: 830px) {
        width: 80%;
        margin-top:10px;
        align-self: center;
    }

    @media screen and (max-width: 400px) {
        
    }
`

export const SocialIcon = styled.a`
    border: 1px solid #000;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    border: 3px solid #fff;
    font-size: 30px;
    cursor: pointer;
    color: #fff;

      &:hover {
    background-color: #024F8A;
    transition: 100ms ease-in;

   
}`


export const ContentCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    @media screen and (max-width: 670px) {
        margin-top: 30px;
      
}
`