import React, { } from 'react'
import {  GIFImage, ContainerMint, ContentSummary, ContentSummaryMint } from './ContentElements'

import MintGif from '../../videos/mint.gif'



const MintSection2 = () => {

    // const hoursMinSecs = { hours: 1, minutes: 20, seconds: 40 }

    // const [mintCount, setMintCount] = useState(1);

    // const handleSubtractOne = () => {
    //     if (mintCount > 1) {
    //         setMintCount(mintCount - 1);
    //     }

    // }

    // const handleAddOne = () => {
    //     if (mintCount < 5) {
    //         setMintCount(mintCount + 1);
    //     }
    // }


    return (
       
            <ContainerMint id={'mint'}>
                {/* <HeaderContainer>
    
                    <ContentHeaderMint>Mint Your Fusion FighterZ</ContentHeaderMint>
                </HeaderContainer> */}

                
                     {/* <Column>
                        <GIFImage src={gif} />
                    </Column> */}
               
                    <ContentSummaryMint style={{textAlign: 'left'}}>Step into a realm where anime meets NFTs. Fusion FighterZ is a vibrant community of 6,300 anime NFTs, underpinned by a play-to-earn multiplayer game. Here, the thrill of competitive battles seamlessly merges with play-to-earn dynamics. Unleash your potential: battle, train, and evolve your NFT fighters. The metaverse championship awaits its legend. Will it be you? Assemble your Fusion FighterZ and stake your claim!</ContentSummaryMint>
                    {/* <MintCard>

                       
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                           
                           >
                            <MintContComponent>

                                <MintContent> 0 / 0 | 0.0 ETH</MintContent>
                                <MintContent>Max 5 per transaction</MintContent>
                           
                            </MintContComponent>
                            <div style={{display: 'flex', flexDirection: 'column'}}
                            
                            >
                                <CounterContainer>
                                    <IconContext.Provider value={{ color: "#000", size: "4em" }}>
                                        <ButtonCounter onClick={handleSubtractOne}>
                                            <MinusButton />
  
                                        </ButtonCounter>
                                    </IconContext.Provider>

                                    <MintCount>
                                        <MintCounter>{mintCount}</MintCounter>
                                    </MintCount>

                                    <IconContext.Provider value={{ color: "#000", size: "4em" }}>
                                        <PlusButtonCounter onClick={handleAddOne}>
                                            <MinusButton2 />
                                            <PlusButton />
                                           
                                        </PlusButtonCounter>
                                    </IconContext.Provider>

                                </CounterContainer>
                                <Button2 >
                                    Mint
                                </Button2>
                            
                            </div>
                      
                        </div>
                            <Timer hoursMinSecs={hoursMinSecs} />

                    </MintCard> */}
                                    

                <GIFImage src={MintGif}/>
                        
                        {/* <Carousel reverse={false}/>
                         <Carousel reverse={true}/> */}

            </ContainerMint>



    )
}

export default MintSection2
