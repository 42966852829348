import styled from 'styled-components';

export const FighterTypesContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 30px 30px;
    height: 1000px;
    background: #757575;

`

export const TypeChartContainer = styled.div`
    background: #757575;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    height: 900px;
    width: 50%;
`
export const TypeChart = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px #70edfe solid;
    box-shadow: 0 0 10px #9ecaed;
    border-radius: 10%;
    overflow: hidden;
`
export const Chart = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px #70edfe solid;
    box-shadow: 0 0 10px #9ecaed;
    border-radius: 10%;
    overflow: hidden;
`

export const ClassInfoContainer = styled.div`
    background: #d3d3d3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px;
    height: 800px;
    width: 50%;
    border-radius: 5%;
`
export const LabelRow = styled.div`
  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 100px;
    width: 100%;
`

export const WeaponRow = styled.div`
background: #202020;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 30px;
    height: 200px;
    width: 100%;
`
export const ClassCont = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    align-items: center;
    width: 200px;
`
export const WeaponRowCont = styled.div`
    background: #202020;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 200px;
    width: 100%;
`
export const MeleeRow = styled.div`
    background: navy;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 200px;
`
export const MeleeRow2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
    height: 200px;
`
export const MagicRow = styled.div`
background: purple;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 200px;
`
export const MagicRow2 = styled.div`
background: purple;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    height: 200px;
`

export const Img = styled.img`
    width: 7rem;
    border-radius: 50%;
    
   `

export const Img2 = styled.img`
    background-color: #505050;
    padding: 15px;
    margin-left: -200px;
    width: 6.8em;
    border-radius: 50%;
   
`

export const Img3 = styled.img`
    background-color: #121212;
    width: 100px;
    border-radius: 50%;
    margin-top: 10px;
`

export const Img3Active = styled.img`
    background-color: #121212;
    width: 100px;
    border-radius: 50%;
    border: 10px solid #70edfe;
    margin-top: 10px;
`

export const Img4 = styled.img`
    background-color: #505050;
    padding: 7px;
    margin-left: -45px;
    margin-bottom: 8px;
    width: 4em;
    border-radius: 50%;
   
`

export const Mock = styled.img`
    width: 500px;
    height: 500px;
    margin-left: auto;
`
export const MockParentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const StatRangeContainer = styled.div`
    margin: 10px;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
  
`
export const StatRangeIndividualCont = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
`

export const Title = styled.h1`
    align-self: center;
    color: #70edfe;
    font-family: "Custom";
    font-size: 62px;
`

export const Summary = styled.p`
    align-self: center;
    margin: 20px;
    color: #fff;
    font-family: "Custom";
    font-size: 26px;
`
export const Link = styled.a`
    color: #70edfe;
    font-family: "Custom";
    font-size: 26px;
`
export const ClassLabel = styled.h1`
    color: #000;
    font-family: "Custom";
    font-size: 62px;
    margin: 10px;
`
export const StatTitle = styled.h2`
    color: #000;
    font-family: "Custom";
    font-size: 42px;
    margin: 10px;
`

export const TypeLabel = styled.h2`
    color: #fff;
    font-family: "Custom";
    font-size: 42px;
    margin: 10px;
`
export const TypeLabelCont = styled.div`
    color: #fff;
    font-family: "Custom";
    font-size: 42px;
    margin: 10px;
`
export const StatLabel = styled.h3`
    color: #000;
    font-family: "Custom";
    font-size: 32px;
`
export const StatFont = styled.h3`
    color: #000;
    font-family: "Custom";
    font-size: 22px;
`
