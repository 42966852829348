import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';

export const Nav = styled.nav`
    background: #000;
    height: 90px;
    box-shadow: 0 0 30px #9ecaed;
    width: 100vw;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    position: fixed;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    z-index: 10;
    width: 100%;

`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex; 
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    margin: 25px;

    

    
    @media screen and (max-width: 768px) {
        width: 136px;

    }
    @media screen and (min-width: 769px) and (max-width: 1080px) {
        width: 19%;
        margin: 0px;
        padding-top: 10px;
    }
    @media screen and (min-width: 1080px) {
        width: 180px;
        padding-top: 10px;
       
    }
`;

export const NavLogo2 = styled(LinkS)`
    color: #fff;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex; 
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    margin: 25px;

    @media screen and (min-width: 10px) and (max-width: 400px) {
        margin: 4px;
        width: 50%;
    }
    @media screen and (min-width: 400px) and (max-width: 515px) {
        width: 45%;
        margin: 0px;
        padding-top: 10px;
    }
    @media screen and (min-width: 515px) and (max-width: 715px) {
        width: 35%;
        margin: 0px;
        padding-top: 10px;
    }
    @media screen and (min-width: 600px) and (max-width: 768px) {
        width: 30%;
        margin: 0px;
        padding-top: 10px;
    }
    @media screen and (min-width: 769px) and (max-width: 1080px) {
        width: 19%;
        margin: 0px;
        padding-top: 10px;
    }
    @media screen and (min-width: 1080px) {
        width: 180px;
        padding-top: 10px;
       
    }
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 10px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavSubMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
  
`;

export const NavSubItem = styled.li`
    height: 40px;
`;

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 50%;
     // box-shadow: 0 0 30px #9ecaed;
    cursor: pointer;
    font-family: "Custom";
    font-size: 32px;
    &.active {
        border-bottom: 3px solid #70edfe;
        color: #70edfe;
    }
    &:hover {
        color: #f26eca;
        transition: 200ms ease-in;
    }

    @media screen and (max-width: 1300px) {
        font-size: 22px;
    }
    @media screen and (max-width: 1100px) {
        font-size: 18px;
        padding: 0 0.5rem;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: #70edfe;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 26px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-family: 'custom';

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`
export const ConnectButton = styled.div`
    border-radius: 50px;
    background: #3d3d3d;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 26px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-family: 'custom';

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`
export const NavLogoImage = styled.img`
    width: 94%;   
`

export const NavCryImage = styled.img`
    width: 50px;   
`
export const NavEndCont = styled.div`
    display: flex;
    flex-direction: row;

`

export const SocialIcon = styled.a`
    border: 1px solid #000;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
      font-size: 40px;
      cursor: pointer;
      color: #fff;

      &:hover {
    background-color: #4649F0;

    transition: 100ms ease-in;
}
  
`
export const SocialIconWP = styled.a`
    border: 1px solid #000;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    margin-right: 10px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
 
  
    font-size: 30px;
    cursor: pointer;
    color: #fff;

      &:hover {
    background-color: #3d3d3d;
    transition: 100ms ease-in;

    @media screen and (max-width: 1004px) {
        
    }
}
  
`

export const SocialIconTwitter = styled.a`
    border: 1px solid #000;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
 
  
    font-size: 30px;
    cursor: pointer;
    color: #fff;

      &:hover {
    background-color: #70edfe;
    transition: 100ms ease-in;

    @media screen and (max-width: 1004px) {
        
    }
}
  
`

export const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1000px) {
        display: none;
    }
`