import React from 'react'
import {
    SidebarContainer, CloseIcon, Icon, SidebarWrapper, SidebarMenu, SidebarLink,
} from './SidebarElements'
import {
      NavLogo, NavLogoImage, 
} from '../Navbar/NavbarElements';
import { animateScroll as scroll } from 'react-scroll';
import Logo from '../../images/logo.png'

const Sidebar = ({isOpen, toggle}) => {
    
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <NavLogo to='/' onClick={toggleHome}  >
                            <NavLogoImage src={Logo}></NavLogoImage>
                    </NavLogo>
                    <SidebarLink to='about'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-100}  onClick={toggle}>About</SidebarLink>
                    <SidebarLink to='mint'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-60} onClick={toggle}>Mint</SidebarLink>
                    <SidebarLink to='gallery'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-60} onClick={toggle}>Gallery</SidebarLink> 
                    <SidebarLink to='offerings'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-95} onClick={toggle}>Offerings</SidebarLink>
                    <SidebarLink to='team'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-100} onClick={toggle}>Team</SidebarLink>
                    <SidebarLink to='roadmap'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-100} onClick={toggle}>Roadmap</SidebarLink>
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to='/signin' onClick={toggle}>Sign in</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
