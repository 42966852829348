import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoImage,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavBtn,
  ConnectButton,
  NavEndCont,
  NavCryImage,
  SocialRow,
  SocialIcon,
  SocialIconTwitter,
  SocialIconWP,
} from "./NavbarElements";
import Logo from "../../images/logo.png";
import { animateScroll as scroll } from "react-scroll";

import { FaDiscord, FaTwitter } from "react-icons/fa";
import Crystal from "../../images/crystal 5.png";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [yPos, setYPos] = useState("");
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
      setYPos(window.scrollY);
    } else {
      setScrollNav(false);
      setYPos(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const handleConnectWallet = () => {
    activateBrowserWallet();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          {window.scrollY >= 340 ? (
            <NavLogo to="/" onClick={toggleHome}>
              <NavLogoImage src={Logo}></NavLogoImage>
            </NavLogo>
          ) : (
            <div />
          )}

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>

          <NavMenu>
            <NavLinks
              to="about"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-100}
            >
              ABOUT
            </NavLinks>

            <NavLinks
              to="mint"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-60}
            >
              MINT
            </NavLinks>

            <NavLinks
              to="gallery"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-60}
            >
              GALLERY
            </NavLinks>

            <NavLinks
              to="offering"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-95}
            >
              OFFERINGS
            </NavLinks>

            {/* <NavLinks to='team'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-100} 
                            >TEAM</NavLinks> */}

            <NavLinks
              to="roadmap"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-100}
            >
              ROADMAP
            </NavLinks>
          </NavMenu>

          <NavEndCont>
            <SocialRow>
              <SocialIcon href=" https://discord.gg/MxS3VTTFTN" target="_blank">
                <FaDiscord color="#fff" />
              </SocialIcon>
              <SocialIconTwitter
                href="https://twitter.com/Fusion_FighterZ"
                target="_blank"
              >
                <FaTwitter color="#fff" />
              </SocialIconTwitter>
              <SocialIconWP
                onClick={() =>
                  window.open("https://whitepaper.fusionfighterz.io/", "_blank")
                }
              >
                <NavCryImage src={Crystal} />
              </SocialIconWP>
            </SocialRow>
            <NavBtn>
              {account ? (
                <div>
                  <h1 style={{ color: "white" }}>
                    {etherBalance &&
                      parseFloat(formatEther(etherBalance)).toFixed(3)}{" "}
                    ETH
                  </h1>
                  <h1 style={{ color: "white" }}>
                    {account &&
                      `${account.slice(0, 6)}...${account.slice(
                        account.length - 4,
                        account.length
                      )}`}
                  </h1>
                </div>
              ) : (
                <ConnectButton onClick={handleConnectWallet}>
                  Connect
                </ConnectButton>
              )}
            </NavBtn>
          </NavEndCont>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
