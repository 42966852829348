import React from 'react'
import {
    HeroContainer,  LogoHero,  HeroContent, HeroImage, HeroP, HeroCon,
} from './HeroElements';
// import {Button} from '../ButtonElement'
import Logo from '../../images/logo.png'
// import FadeInSection from '../FadeIn';
import BgAura2 from '../../images/bgAura2.png'


const HeroSection = () => {
    return (
        <HeroContainer id='hero'>
                     
            <HeroContent>
                
                    {/* style={{display: 'flex',marginTop: '100px', flexDirection: 'row'}}> */}
                  
                  
                    <LogoHero src={Logo}></LogoHero>
                 
                    
                    {/* <CrystalHero src={Crystal}></CrystalHero> */}
               
                <HeroCon>
                    {/* <HeroH1>Metaverse Grand Tournament</HeroH1>
                     */}
                    <HeroP>
                        Collect, Fight, and Earn!
                    </HeroP>


                    
                    {/* <HeroBtnWrapper>
                        <Button to='mint'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-100} >
                            Mint
                        </Button>
                    </HeroBtnWrapper> */}

                </HeroCon>
                
                <HeroImage src={BgAura2}/>


            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
