import { Button, FrameUnderline, ArwesThemeProvider } from "@arwes/core";
import React, { useEffect } from "react";
import LiscenceVid2 from "../../videos/Lisence.gif";
import LiscenceVid from "../../videos/Liscence.mp4";
import {
  ContainerLiscence,
  ButtonCont,
  LiscenceContainer,
  LiscenceImageCont,
  OffHeader,
  OffSummary,
  OffSummaryCont,
} from "./OfferingElements";
import { VideoComp } from "../HeroSection/HeroElements";
import FadeInSection from "../FadeIn";
import { LisenceImage } from "./ContentElements";
import { useMediaQuery } from "react-responsive";

const Liscence = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 670px)",
  });

  useEffect(() => {}, []);

  const palette = {
    primary: { main: "#70edfe" },
    secondary: { main: "#c466dc" },
    neutral: { main: "#001711" },
    text: {
      root: "#35efaa",
      headings: "#40ffce",
      link: "#c466dc",
      linkHover: "#d491fa",
    },
  };

  let themeSettings = { palette };

  return (
    <ArwesThemeProvider themeSettings={themeSettings}>
      <ContainerLiscence id={"offering"}>
        <LiscenceContainer>
          <LiscenceImageCont>
            {isMobileDevice ? (
              <LisenceImage src={LiscenceVid2} />
            ) : (
              <VideoComp playsinline autoPlay muted loop controls="">
                <source src={LiscenceVid}></source>
              </VideoComp>
            )}
          </LiscenceImageCont>

          <FadeInSection>
            <OffSummaryCont>
              <OffHeader>Fight License</OffHeader>
              <OffSummary>
                The Fight License is your passport to the Metaverse Grand
                Tournament. Think of it as the master key to the Fusion FighterZ
                realm, enabling NFT projects to ascend the ranks and vie for
                unmatched rewards. It's not just a license—it's an enhancement,
                offering NFT projects a window to a bustling play-to-earn world
                and the pioneering multi-project NFT combat game.
              </OffSummary>
              <ButtonCont>
                <Button
                  style={{ alignSelf: "flex-end", marginTop: "10px" }}
                  palette="primary"
                  FrameComponent={FrameUnderline}
                  onClick={() =>
                    window.open(
                      "https://whitepaper.fusionfighterz.io/fight-license",
                      "_blank"
                    )
                  }
                >
                  Learn More
                </Button>
              </ButtonCont>
            </OffSummaryCont>
          </FadeInSection>
        </LiscenceContainer>
      </ContainerLiscence>
    </ArwesThemeProvider>
  );
};

export default Liscence;
