import React from 'react'
import { TopLine, ContainerOther, Column4, SubLogos, OtherColCont, SubLogo2, OtherRowCont } from './ContentElements'
import "react-responsive-carousel/lib/styles/carousel.min.css"; //requires loader
import PharoLogo from '../../images/Pharo-logo-white.png'
import UnityLogo from '../../images/unity-logo-white.png'
import AwsLogo from '../../images/aws-logo.png'

const Other = () => {

    return (
       
            <ContainerOther id={'other'}>
               
                <Column4>
                    <OtherColCont>
                        <TopLine>Powered By</TopLine>
                        <OtherRowCont>
                            <SubLogo2 src={AwsLogo} alt="Powered by AWS Cloud Computing"/>
                    
                            <SubLogo2 src={UnityLogo}/>
                           
                        </OtherRowCont>
                    </OtherColCont>
                
                    
                    
                </Column4>
                <Column4>
                    <OtherColCont>
                        <TopLine>Developer</TopLine>
                        <SubLogos src={PharoLogo}/>
                    </OtherColCont>
                
                    
                    
                </Column4>
              
                
            </ContainerOther>
       
    )
}

export default Other



//<ContentSummary>Fusion FighterZ is a NFT multiplayer battle arena within the Metaverse inspired by Axie Infinity and the Pokemon battle system, where players can earn tokens through skilled gameplay and participating in the ecosystem. Players can battle, improve, and fuse their NFT fighters for an immersive and lucrative gameplay experience. Using Blockchain economic design allows Fusion FighterZ to develop a Play-To-Earn gaming ecosystem. Anyone from anywhere can earn real financial rewards while having fun playing and mastering a strategy based multiplayer game.</ContentSummary>
                    