import React from "react";
import {
  ContentComponent,
  Column,
  ContainerOfferings,
} from "./ContentElements";

import "react-responsive-carousel/lib/styles/carousel.min.css"; //requires loader
import { useMediaQuery } from "react-responsive";

import { ArwesThemeProvider } from "@arwes/core";

import Offering from "./Offering";
import Crystal from "../../images/crystal 5.png";
import Orb from "../../images/orb.png";
import BMArt from "../../images/BattleMovesArt.png";

// import { loadFull } from "tsparticles";
import FighterTypes from "../FighterTypes/NewDesign";

import FadeInSection from "../FadeIn";
import Earn from "./Earn";

const QuickSummary = () => {
  // const [offsetY, setOffsetY] =useState(0);
  // const handleScroll = () => setOffsetY(window.pageYOffset)

  // useEffect(()=> {
  //     window.addEventListener('scroll', handleScroll)

  //     return () => window.removeEventListener("Scroll", handleScroll);
  // }, [])

  /*
        React Responsiveness Media Queries
    */
  // const isTabDevice = useMediaQuery({
  //     query: "(max-width: 830px)",
  // });

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 1250px)",
  });

  const palette = {
    primary: { main: "#70edfe" },
    secondary: { main: "#c466dc" },
    neutral: { main: "#001711" },
    text: {
      root: "#35efaa",
      headings: "#40ffce",
      link: "#c466dc",
      linkHover: "#d491fa",
    },
  };

  let themeSettings = { palette };
  // const particlesInit = async (main) => {
  //     console.log(main);

  //     // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  //     // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  //     // starting from v2 you can add only the features you need reducing the bundle size
  //     await loadFull(main);
  //   };

  //   const particlesLoaded = (container) => {
  //     console.log(container);
  //   };

  return (
    <ArwesThemeProvider themeSettings={themeSettings}>
      <ContainerOfferings id={"offerings"}>
        {/* <HeaderContainer>
                        
                        <ContentHeaderGS>Welcome to the Metaverse Grand Tournament</ContentHeaderGS>
                    </HeaderContainer> */}

        <ContentComponent>
          <Column>
            {/* <Project header='Play to Earn'>

                                <ContentSummary>Fusion FighterZ is a NFT Battle Arena located in the Metaverse. With the rise of Play to Earn gaming people ought to own and be compensated for the value and effort they put into their games.</ContentSummary>
                                <ContentSummary> Enter the Fusion FighterZ Battle Arena to collect, fight, and earn!</ContentSummary>

                            </Project> */}

            {isMobileDevice ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                <FadeInSection>
                  <Offering
                    style={{
                      transform: "translateY(${offsetY * 0.8}px)",
                      height: "90vh",
                    }}
                    Img={Orb}
                    ImgFirst={true}
                    Header={"Summoning Orb"}
                    Wp={"https://whitepaper.fusionfighterz.io/summoning-orb"}
                    Summary={
                      "Embark on a quest with the Summoning Orbs—ancient talismans pulsating with the legacies of legendary fighters of yore. Secure your orb, beckon your Fusion FighterZ, and set your sights on becoming the metaverse monarch. Crafted on the Ethereum blockchain and designed to optimize your experience, the orb ensures swift fighter airdrops on the polygon network, ensuring minimal gas fees."
                    }
                  />
                </FadeInSection>

                <FadeInSection>
                  <Offering
                    Img={BMArt}
                    style={{ height: "95vh" }}
                    Header={"Battle Moves"}
                    ImgFirst={true}
                    Wp={
                      "https://whitepaper.fusionfighterz.io/gameplay/battle-moves"
                    }
                    Summary={
                      "Every move is a dance with Aura, the primal energy that fuels the Fusion FighterZ. The elite earn the honor of these moves from the high council, a testament to their prowess. Experiment, fuse, and devise moves that resonate with heightened Aura—turning the battle in your favor."
                    }
                  />
                </FadeInSection>

                <FadeInSection>
                  <Offering
                    Img={Crystal}
                    style={{ height: "95vh" }}
                    Header={"Fusion"}
                    ImgFirst={true}
                    Wp={
                      "https://whitepaper.fusionfighterz.io/fusion/fusion-intro"
                    }
                    Summary={
                      "In the heat of battle, the densest Aura solidifies into Fusion Crystals. These coveted artifacts, scattered across battlefields, can merge Fusion FighterZ or Battle Moves. By harnessing the combined aura of fighters or moves, these crystals birth a mightier NFT entity."
                    }
                  />
                </FadeInSection>

                <FadeInSection>
                  <Earn />
                </FadeInSection>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                <FadeInSection>
                  <Offering
                    style={{
                      transform: "translateY(${offsetY * 0.8}px)",
                      height: "95vh",
                    }}
                    Img={Orb}
                    ImgFirst={true}
                    Header={"Summoning Orb"}
                    Wp={"https://whitepaper.fusionfighterz.io/summoning-orb"}
                    Summary={
                      "The summoning orbs are ancient relics infused with the Aura of legendary fighters throughout history. Collect your Summoning Orb and summon your Fusion FighterZ to become champion of the Metaverse. The orb is minted on the Ethereum blockchain as an ERC1155 token and will airdrop your fighter on the polygon network to minimize gas fees."
                    }
                  />
                </FadeInSection>

                <FadeInSection>
                  <Offering
                    Img={BMArt}
                    style={{ height: "95vh" }}
                    Header={"Battle Moves"}
                    ImgFirst={false}
                    Wp={
                      "https://whitepaper.fusionfighterz.io/gameplay/battle-moves"
                    }
                    Summary={
                      "Battle moves utilize Aura, the life force energy harnessed by the Fusion FighterZ to unleash powerful special attacks. The high council grants these moves to the strongest fighters as a reward for their hard work and prestige. Moves can be fused together to create higher Aura concentrated attacks that can turn the tide of battle."
                    }
                  />
                </FadeInSection>

                <FadeInSection>
                  <Offering
                    Img={Crystal}
                    style={{ height: "95vh" }}
                    Header={"Fusion"}
                    ImgFirst={true}
                    Wp={
                      "https://whitepaper.fusionfighterz.io/fusion/fusion-intro"
                    }
                    Summary={
                      "An immense amount of concentrated Aura can crystallize, forming Fusion Crystals. Which can generally be found on the field of battle and can be utilized to fuse Fusion FighterZ or Battle Moves together. Fusion crystals integrate the aura of two fighters or three Battle Moves to create a new, even stronger NFT."
                    }
                  />
                </FadeInSection>

                {/* <FadeInSection>
                                        <Offering
                                            Img={Coin}
                                            OffId={'earn'}
                                            Header={'Earn!'}
                                            ImgFirst={false}
                                            Summary={"The summoning orb is the creation of the high council that captures the energy of the Zuhk and uses it to summon the Fusion FighterZ. The orb is minted on the Ethereum blockchain as an ERC1155 token and will airdrop your fighter on the polygon network to minimize the gas fees. Owning a summoning orb will grant you many rewards including airdrops, early access, and many more future benefits."}
                                        />
                                    </FadeInSection> */}

                <FadeInSection>
                  <Earn />
                </FadeInSection>

                <FighterTypes />

                {/* <Team/> */}
              </div>
            )}
          </Column>
        </ContentComponent>
      </ContainerOfferings>
    </ArwesThemeProvider>
  );
};

export default QuickSummary;

//<ContentSummary>Fusion FighterZ is a NFT multiplayer battle arena within the Metaverse inspired by Axie Infinity and the Pokemon battle system, where players can earn tokens through skilled gameplay and participating in the ecosystem. Players can battle, improve, and fuse their NFT fighters for an immersive and lucrative gameplay experience. Using Blockchain economic design allows Fusion FighterZ to develop a Play-To-Earn gaming ecosystem. Anyone from anywhere can earn real financial rewards while having fun playing and mastering a strategy based multiplayer game.</ContentSummary>
