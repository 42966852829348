import React from 'react'
import './HexSmal.scss'
import {  FirstContainer3 } from './FighterInfoElements'

import { Img3, Img3Active } from './TypeChartElements'

const HexSmall = ({Image, Active, handleClick}) => {

    

    return (
        <FirstContainer3 >
              

                
                    {Active ? (          
                        <Img3Active src={Image} />
                    

                    ) :
                        
                        <Img3 src={Image} onClick={handleClick}/>
                    
                    }
                
                  
            
        </FirstContainer3>
    )
}

export default HexSmall
