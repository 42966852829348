import styled from "styled-components";

export const Box = styled.div`
padding: 43px 60px;
background: black;
position: absolute;
width: 100%;

 
@media (max-width: 1090px) {
  padding: 0px 0px;
}
`
 
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 85%;
`
 
export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 60px;

@media screen and (max-width: 1090px) {
      
      margin-left: 30px;
    }
`;
export const FirstColumn = styled.div`
display: flex;
flex-direction: column;
align-self: center;
text-align: left;
width: 25%;
margin-bottom: 10px;
`;
 
export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding-bottom: 40px;
margin-bottom: 20px;

@media screen and (max-width: 1090px) {
        flex-direction: column;
        padding-bottom: 0px;
      }

`;

export const RowRespo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  @media screen and (max-width: 1090px) {
      align-self: center;

    }

`;

export const Row2 = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
@media screen and (max-width: 1090px) {
      
      margin-bottom: 35px;
    }
`;
 
export const FooterLink = styled.a`
color: #fff;
margin-bottom: 20px;
font-size: 32px;
text-decoration: none;
font-family: "Custom";
 
&:hover {
    color: #f26eca;
    transition: 200ms ease-in;
}

@media screen and (max-width: 1090px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
`;

export const FooterLink2 = styled.a`
color: #fff;

font-size: 16px;
text-decoration: none;
font-family: "Content";
 margin-right: 15px;
&:hover {
    color: #f26eca;
    transition: 200ms ease-in;
}
@media screen and (max-width: 1090px) {
      font-size: 12px;
    }
`;
 
export const Heading = styled.p`
font-size: 36px;
color: #70edfe;
margin-bottom: 40px;
font-weight: bold;
font-family: "Custom";

@media screen and (max-width: 1090px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
`;
export const SubHeading = styled.p`
font-size: 16px;
color: #70edfe;
margin-right: 30px;
font-weight: bold;



@media screen and (max-width: 1090px) {
      font-size: 12px;
      
    }
`;

export const NavLogoImage = styled.img`
    width: 100%;
    
    @media screen and (max-width: 1090px) {
      font-size: 12px;
      
    }
`

export const SocialIcon = styled.a`
    border: 1px solid #000;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
      font-size: 40px;
      cursor: pointer;
      color: #fff;

      &:hover {
        background-color: #4649F0;
    transition: 100ms ease-in;
}
  
`
export const SocialIconTwitter = styled.a`
    border: 1px solid #000;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  
    font-size: 30px;
    cursor: pointer;
    color: #fff;

      &:hover {
    background-color: #70edfe;
    transition: 100ms ease-in;
}
  
`

export const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 75%;
  align-self: center;
`