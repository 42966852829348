import React from "react";
import Logo from '../../images/logo.png'
import {FaDiscord, FaTwitter} from 'react-icons/fa'
import Crystal from '../../images/crystal 5.png'
import { NavCryImage, SocialIcon,
  SocialIconTwitter,SocialIconWP } from "../Navbar/NavbarElements";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  NavLogoImage,
  FirstColumn,
  
  SocialRow,
  SubHeading,
  FooterLink2,
  Row2,
  RowRespo,
} from "./FooterElements";
import PharoLogo from '../../images/Pharo-logo-white.png'
import UnityLogo from '../../images/UE_Logo.png'
import AwsLogo from '../../images/aws-logo.png'
import { SubLogo3, SubLogos, OtherColCont, SubLogo2,  } from './ContentElements'

  
const Footer = () => {
  return (
    <Box>
      {/* <h1 style={{ color: "green", 
                   textAlign: "center", 
                   marginTop: "-50px" }}>
        GeeksforGeeks: A Computer Science Portal for Geeks
      </h1> */}
      <Container>
        <Row>
            <FirstColumn>
                
                <NavLogoImage src={Logo}></NavLogoImage>
                <SocialRow>
                  <SocialIcon href=' https://discord.gg/MxS3VTTFTN' target="_blank" >
                    <FaDiscord color="#fff"/>
                  </SocialIcon>
                  <SocialIconTwitter  href='https://twitter.com/Fusion_FighterZ' target="_blank" >
                    <FaTwitter color="#fff"/>
                  </SocialIconTwitter>

                  <SocialIconWP onClick={() => window.open('https://whitepaper.fusionfighterz.io/', '_blank')}>
                                <NavCryImage src={Crystal}/>
                    </SocialIconWP>
                </SocialRow>
                
                
                
            </FirstColumn>
              
            <RowRespo>
              <Column>
                <Heading>About</Heading>
                <FooterLink onClick={() => window.open('https://whitepaper.fusionfighterz.io/', '_blank')}>Whitepaper</FooterLink>
                {/* <FooterLink onClick={() => window.open('https://whitepaper.fusionfighterz.io/economy/game-token-usdzcoin', '_blank')}>Tokenomics</FooterLink> */}
                <FooterLink href="#">FAQ</FooterLink>
              </Column>
              
              
          
              
              <Column>
              
                  <OtherColCont>
                      <Heading>Powered By</Heading>
                      <OtherColCont>

                          <SubLogo2 src={UnityLogo}/>

                          <SubLogo3 src={AwsLogo} alt="Powered by AWS Cloud Computing"/>

                      </OtherColCont>
                  </OtherColCont>
              
                  
              </Column>

              <Column>
          
                    {/* <OtherColCont>
                        <Heading>Developer</Heading>
                        <OtherColCont>
                            <SubLogos src={PharoLogo}/>
                        </OtherColCont>
                        
                    </OtherColCont> */}
                    
              </Column>

            </RowRespo>  
        </Row>
        <Row2>
          <SubHeading>© 2022 FUSION FIGHTERZ ALL RIGHTS RESERVED</SubHeading>
            <FooterLink2 href="#">Terms of use</FooterLink2>
            <FooterLink2 href="#">Privacy Policy</FooterLink2>
        </Row2>
      </Container>
    </Box>
  );
};
export default Footer;