import React from 'react'
import './HexMini.scss'
import {  FirstContainer4 } from './FighterInfoElements'

import {  Img4 } from './TypeChartElements'
const HexMini = ({Image, Active, handleClick}) => {

    

    return (
        <FirstContainer4 >
            <Img4 src={Image} />
            <div className='button3'>
                {Active ? (
                    <div className='selected3'>
                        <div/>
                        <div/>
                        <div/>   
                    </div>

                ) :
                <div className='hex3' onClick={handleClick}>
                    <div/>
                    <div/>
                    <div/>   
                </div>
                }
                  
                
            </div>
            
        </FirstContainer4>
    )
}

export default HexMini

