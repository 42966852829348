import React from 'react'
import {  HeaderContainer, Column,  ContentHeaderGS, ContainerTeam, ContentSummary2 } from './ContentElements'

import "react-responsive-carousel/lib/styles/carousel.min.css"; //requires loader
import {useMediaQuery, } from 'react-responsive'

import { ArwesThemeProvider, FrameUnderline, Button, } from '@arwes/core'
import Pharo from '../../images/Pharo-logo-white.png'
import { IndTeamCont, TeamCaption, TeamCont, TeamImage, TeamMemCont, PharoImg, TeamSumCont } from './OfferingElements';
import Team1 from '../../images/team1.png'
import Team2 from '../../images/team2.png'
import Team3 from '../../images/team3.png'
import Shiba from '../../images/ShibaMock.png'
import Maqro from '../../images/Maqro_Mock.png'
import Lucky from '../../images/Lucky_Mock.png'
import Zoldyck from '../../images/Zoldyck_Mock.png'

import FadeInSection from '../FadeIn';
const Team = () => {

    /*
        React Responsiveness Media Queries
    */
    // const isTabDevice = useMediaQuery({
    //     query: "(max-width: 830px)",
    // });

    const isMobileDevice = useMediaQuery({
        query: "(max-width: 620px)",
    });


    const palette = {
        primary: { main: '#70edfe' },
        secondary: { main: '#c466dc' },
        neutral: { main: '#001711' },
        text: {
          root: '#35efaa',
          headings: '#40ffce',
          link: '#c466dc',
          linkHover: '#d491fa'
        }
      };

    let themeSettings = {palette};

    return (
        <ArwesThemeProvider themeSettings={themeSettings}>
            <FadeInSection>
            <ContainerTeam id={'team'}>
                <HeaderContainer>
                    {/* <TopLine>Fusion FighterZ</TopLine> */}
                    <ContentHeaderGS>The High Council</ContentHeaderGS>
                </HeaderContainer>

                
                    <Column style={{width: '70%'}}>
                        {/* <Project header='Play to Earn'>

                            <ContentSummary>Fusion FighterZ is a NFT Battle Arena located in the Metaverse. With the rise of Play to Earn gaming people ought to own and be compensated for the value and effort they put into their games.</ContentSummary>
                            <ContentSummary> Enter the Fusion FighterZ Battle Arena to collect, fight, and earn!</ContentSummary>

                        </Project> */}

                        {isMobileDevice ? (
                           <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', width: "100%"}}>
                                
                           <TeamCont>
                              
                                    <TeamMemCont>
                                        <IndTeamCont>
                                            <TeamImage src={Team2}/>
                                            <TeamCaption>Pharo</TeamCaption>
                                            <TeamCaption>CEO</TeamCaption>
                                        </IndTeamCont>
                                       
                                        <IndTeamCont>
                                            <TeamImage src={Zoldyck}/>
                                            <TeamCaption>Zoldyck</TeamCaption>
                                            <TeamCaption>CTO</TeamCaption>
                                        </IndTeamCont>

                                        <IndTeamCont>
                                            <TeamImage src={Shiba}/>
                                            <TeamCaption>Shiba</TeamCaption>
                                            <TeamCaption>Art Director</TeamCaption>
                                        </IndTeamCont>

                                        
                                    </TeamMemCont>

                               <TeamMemCont style={{justifyContent: 'center'}}>
                                        <IndTeamCont style={{marginRight: '5%'}}>
                                            <TeamImage src={Maqro}/>
                                            <TeamCaption>Maqro</TeamCaption>
                                            <TeamCaption>Community Manager</TeamCaption>
                                        </IndTeamCont>
                                       
                                        <IndTeamCont style={{marginLeft: '5%'}}>
                                            <TeamImage src={Lucky}/>
                                            <TeamCaption>Lucky</TeamCaption>
                                            <TeamCaption>Marketing Lead</TeamCaption>
                                        </IndTeamCont>

                                      

                                        
                                    </TeamMemCont>
                           </TeamCont>
                           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',width: "90%"}}>

{/*            
                               <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px',justifyContent: 'space-around'}}>
                                   <ContentSummary2>The project ecosystem and game is being developed by Pharo Labs and is being built on their Play to Earn gaming platform Playomics. Ownership of the game will be transitioned to the players and members of the community DAO, allowing this game to expand in the desired direction of its players.</ContentSummary2>
                                   <ContentSummary2>Join us in building the Fusion FighterZ Metaverse we are a DAO owned and shaped by our community.</ContentSummary2>
                                   <SocialIcon>
                                       <FaDiscord size={'60px'}/>
                                   </SocialIcon>

                                     <div style={{display: 'flex', flexDirection: 'column', marginLeft: '40px'}}>
                                       <PharoImg src={Pharo}></PharoImg>
                                        <Button style={{ alignSelf: 'flex-end', marginTop: '10px', }} palette='primary' FrameComponent={FrameUnderline}>Learn More</Button>


                                    </div>
                               </div> */}

                             
                           </div>

                           
                       </div>
                        ) : (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', width: "100%"}}>
                                
                                <TeamCont>
                                   
                                    <TeamMemCont>
                                        <IndTeamCont>
                                            <TeamImage src={Team2}/>
                                            <TeamCaption>Pharo</TeamCaption>
                                            <TeamCaption>CEO</TeamCaption>
                                        </IndTeamCont>
                                       
                                        <IndTeamCont>
                                            <TeamImage src={Zoldyck}/>
                                            <TeamCaption>Zoldyck</TeamCaption>
                                            <TeamCaption>CTO</TeamCaption>
                                        </IndTeamCont>

                                        <IndTeamCont>
                                            <TeamImage src={Shiba}/>
                                            <TeamCaption>Shiba</TeamCaption>
                                            <TeamCaption>Art Director</TeamCaption>
                                        </IndTeamCont>

                                        
                                    </TeamMemCont>


                                    <TeamMemCont style={{justifyContent: 'center'}}>
                                        <IndTeamCont style={{marginRight: '5%'}}>
                                            <TeamImage src={Maqro}/>
                                            <TeamCaption>Maqro</TeamCaption>
                                            <TeamCaption>Community Manager</TeamCaption>
                                        </IndTeamCont>
                                       
                                        <IndTeamCont style={{marginLeft: '5%'}}>
                                            <TeamImage src={Lucky}/>
                                            <TeamCaption>Lucky</TeamCaption>
                                            <TeamCaption>Marketing Lead</TeamCaption>
                                        </IndTeamCont>

                                      

                                        
                                    </TeamMemCont>
                                </TeamCont>
                                {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',width: "90%"}}>

                
                                    <TeamSumCont>
                                        <ContentSummary2>The project ecosystem and game is being developed by Pharo Labs and is being built on their Play to Earn gaming platform Playomics. Ownership of the game will be transitioned to the players and members of the community DAO, allowing this game to expand in the desired direction of its players.</ContentSummary2>
                                        <ContentSummary2>Join us in building the Fusion FighterZ Metaverse we are a DAO owned and shaped by our community.</ContentSummary2>
                                        <SocialIcon>
                                            <FaDiscord size={'60px'}/>
                                        </SocialIcon>
                                   </TeamSumCont>

                                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '40px'}}>
                                        <img alt='' style={{height: '225px'}}src={Pharo}></img>
                                        <Button style={{ alignSelf: 'flex-end', marginTop: '10px', }} palette='primary' FrameComponent={FrameUnderline}>Learn More</Button>


                                    </div>
                                </div> */}

                                
                            </div>

                        )}
                        
                    </Column>
                    
               
                
            </ContainerTeam>
            </FadeInSection>
        </ArwesThemeProvider>
       
    )
}

export default Team



//<ContentSummary>Fusion FighterZ is a NFT multiplayer battle arena within the Metaverse inspired by Axie Infinity and the Pokemon battle system, where players can earn tokens through skilled gameplay and participating in the ecosystem. Players can battle, improve, and fuse their NFT fighters for an immersive and lucrative gameplay experience. Using Blockchain economic design allows Fusion FighterZ to develop a Play-To-Earn gaming ecosystem. Anyone from anywhere can earn real financial rewards while having fun playing and mastering a strategy based multiplayer game.</ContentSummary>
                    